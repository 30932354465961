import React, {useState} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import GlobalState, { changepopupdeleteWorkspace, addactivedataset} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"

const PopupDeleteWorkspace = observer(() =>{


    const deleteproductline = async () => {
        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `segmentations/workspace/`+GlobalState.activedatasetSegment.idWorkspace, {
            method: 'DELETE',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        if(response.status=="200")
        {
            var dataset = {
                id: "",
                idWorkspace: "",
                nameSegment: "",
                nameDataset: "",
                Columns: "", 
                Rows: "", 
                file_key: "",
                file_name: "",
                key_column: "",
                source: "",
                status: "",
                upload_time: "",
                user_id: ""
            };
            addactivedataset(dataset);
            changepopupdeleteWorkspace();
            window.location.href = window.location.href  
        }
        
    };
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    return (
        <div className="popupInfofon ">
            <div className="popupInfo deleteProduct">
                <div className='top-element'>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className='buttonexit' onClick={changepopupdeleteWorkspace}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='form-element'>
                    <div className='titlePopupDelete' dangerouslySetInnerHTML={{ __html: langv?.groupsettings?.popupdeleteproduct?.text5 }}>
                        
                    </div>
                    <div className='buttonsPopupDelete'>
                        <div className='buttonPopupDelete' onClick={changepopupdeleteWorkspace}>
                            {langv?.groupsettings?.popupdeleteproduct?.text2}
                        </div>
                        <div className='buttonPopupDelete' onClick={() =>deleteproductline()}>
                            {langv?.groupsettings?.popupdeleteproduct?.text3}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PopupDeleteWorkspace;
