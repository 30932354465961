import React, {useState, useEffect} from 'react';
import Select, { components } from 'react-select'
import { observer } from 'mobx-react'
import './index.css';
import GlobalState, {addModels, changeActiveModel, changepopupaddAttachmentAnalitics} from "../../stores/GlobalState";
import Pool from "../autorize/UserPool";
import Domen from "../../stores/Domen";
import Loading from '../common/loading';
import LoadingCompleted from '../common/loading_completed';
import LoadingFail from '../common/loading_fail';
import Accuracy from '../common/accuracy';
import Contop from '../common/contop';
import TabsTarget from '../common/tabstarget';
import Fourblock from '../common/fourblock';
import TopField from '../common/topfield';
import Priview from '../common/preview';
import Metric from '../common/metric';
import lang from "../../lang"

const Addmodels = observer(({activemodel, setAddmodel, load, setLoad}) => {
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [optionfields, setOptionfields] = useState([]);
    const [namemodal, setNamemodal] = useState(activemodel.name);
    const [countmodal, setCountmodal] = useState(GlobalState.activemodel?.lastTrain?.learningCyclesCount);
    const [optiontype, setOptiontype] = useState(GlobalState.activemodel?.lastTrain?.learningType);
    const [optionmetrics, setOptionmetrics] = useState(GlobalState.activemodel?.lastTrain?.featureDatasetName);
    const [optiontarget, setOptiontarget] = useState(GlobalState.activemodel?.lastTrain?.targetDatasetName);
    const [optiontargetfields, setOptiontargetfields] = useState(GlobalState.activemodel?.lastTrain?.objectId);
    const [disablebut, setDisablebut] = useState(true)

    const [disabletitle, setDisabletitle] = useState(false)
    const [disablebody, setDisablebody] = useState(false)


    let options = [];
    let optionstype = [{value: "binary", label: "Binary"}, {value: "multiclass", label: "Multiclass"}, {value: "regression", label: "Regression"}];
    let optionsstep = [{value: "1", label: "1"},{value: "2", label: "2"},{value: "3", label: "3"},{value: "4", label: "4"},{value: "5", label: "5"},{value: "10", label: "10"},{value: "15", label: "15"},{value: "20", label: "20"},];
    
    GlobalState.dataset?.map((n, id)=>
        options = [...options, { value: n.id, label: n.name }]
    )

    
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: 'rgba(255, 255, 255, 1)',
            display: 'flex',
            flexWrap: 'nowrap',
            width: 'calc(100% - 20px)',
            height: '38px',
            color: 'rgba(2, 2, 3, 1)',
            border: '1px solid rgba(255, 255, 255, 1)',
            boxShadow: 'none',
            fontSize: 16,
            '&:hover': {
                border: '1px solid rgba(245, 197, 56, 1)',
            },
        }),
        
        menu: (provided) => ({
            ...provided,
            background: 'rgba(255, 255, 255, 1)',
            width: 'calc(100% - 20px)',
            marginTop: '0px',
            boxShadow: 'none',
            cursor: 'pointer',
            color: 'rgba(2, 2, 3, 0.6)',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 16,
            paddingLeft: 0,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'rgba(2, 2, 3, 1)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const DropdownIndicator = props => {
        return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6.8451" cy="9.46229" r="1.53846" fill="#020203"/>
                        <circle cx="6.8451" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                        <circle cx="6.8451" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                        <path d="M0.691406 7.15385L7.03173 1L12.9991 7.15385" stroke="#020203"/>
                        <path d="M0.691406 7.15385L7.03173 1L12.9991 7.15385" stroke="black" stroke-opacity="0.2"/>
                        <path d="M0.691406 7.15385L7.03173 1L12.9991 7.15385" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                    : 
                    !disablebut?
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30786 3.3584)" fill="#020203"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30786 3.3584)" fill="black" fill-opacity="0.2"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30786 3.3584)" fill="black" fill-opacity="0.2"/>
                        <path d="M0.692383 4.1274L7.03271 10.2812L13.0001 4.1274" stroke="#020203"/>
                        <path d="M0.692383 4.1274L7.03271 10.2812L13.0001 4.1274" stroke="black" stroke-opacity="0.2"/>
                        <path d="M0.692383 4.1274L7.03271 10.2812L13.0001 4.1274" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                    :
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.2">
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07617)" fill="#8E8E93"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="#8E8E93"/>
                        </g>
                    </svg>

                }
            </components.DropdownIndicator>
        )
        );
    };
    const changecategorytarget = (val) =>{
        
        var op = [];
        GlobalState.dataset.map((n, id)=>{
            n.id==val?
            Object.entries(n?.columnNames).map(([key, value])=>{
                op = [...op, { value: value, label: value}]
            })
            :
            <></>
        })
        setOptiontarget(val);
        setOptionfields(op);
    }


   
    const [textloading, setTextloading] = useState(langv?.open_model?.text1);
    const [textloadingcompleated, setTextloadingcompleated] = useState(langv?.open_model?.text2);
    
    const addmodel = () =>{

        GlobalState.activemodel?.lastTrain?.deploymentState=='done'||GlobalState.activemodel?.lastTrain?.deploymentState=='in_progress'?
        <></>
        :
        changepopupaddAttachmentAnalitics(0)


        // setTextloading("Unfolding");
        // setTextloadingcompleated("Deployment completed")
        // setLoad(1);
        // getModels();
    }

    useEffect(() => {
        var op = [];
        GlobalState.dataset.map((n, id)=>{
            n.id==GlobalState.activemodel?.lastTrain?.targetDataset?
            Object.entries(n?.columnNames).map(([key, value])=>{
                op = [...op, { value: value, label: value}]
            })
            :
            <></>
        })
        setOptionfields(op);
       // setCountmodal(GlobalState.activemodel?.lastTrain?.learningCyclesCount)
        
    }, [])

    useEffect(() => {
        GlobalState.activemodel?.lastTrain?.state=='in_progress'?setLoad(1):setLoad(3)
        //setCountmodal(GlobalState.activemodel?.lastTrain?.learningCyclesCount)
    }, [GlobalState.activemodel?.lastTrain?.metadata?.metrics])
  

    async function getModel(activemodel)
    {
        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `model/`+activemodel, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();
           
            if(data.lastTrain.state=="done"&&data.lastTrain?.metadata?.length>0)
            {
                changeActiveModel(data);
                setLoad(2);
                setTimeout(function(){
                    setLoad(3);
                }, 2000)
            }
            else if(data.lastTrain.state=="failed")
            {
                changeActiveModel(data);
                setLoad(2);
                setTimeout(function(){
                    setLoad(4);
                }, 2000)
            }
            else{
                changeActiveModel(data);
                setTimeout(function(){
                    getModel(activemodel)
                }, 30000)
                
            }
            
        }
    } 

    const retrain = async () =>{
        if(GlobalState.activemodel?.lastTrain?.deploymentState=='done'||GlobalState.activemodel?.lastTrain?.deploymentState=='in_progress')
        {

        }
        else
        {
            setDisablebut(false)
            setLoad(0);
        }
      
        
    }
    const startmodel = async()=>{
        setLoad(1);
        var token = localStorage.getItem("token");
     
        var model = {
            featureDataset: optionmetrics||GlobalState.activemodel?.lastTrain?.featureDataset,
            targetDataset: optiontarget||GlobalState.activemodel?.lastTrain?.targetDataset,
            targetColumnName: optiontargetfields||GlobalState.activemodel?.lastTrain?.targetColumnName,
            learningType: optiontype||GlobalState.activemodel?.lastTrain?.learningType,
            learningCyclesCount: countmodal||GlobalState.activemodel?.lastTrain?.learningCyclesCount,
        };

        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `model/`+ GlobalState.activemodel?.id + `/train`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model),
        })
        if(response.status=="200")
        {
            const data = await response.json();
           
            getModel(data.id)
            //setLoad(2);
            // setTimeout(function(){
            //     setLoad(3);
            // }, 2000)
        }
        else{
            setLoad(4);
        }
    }

    const setAddmodel0 = ()=>{
        window.location.href='/analytics'
    }
    
    return (
        <div className='addmodel_block'>
           <div className='addmodel_head'>
                <div className='addmodel_head_tabs'>
                    <div className='addmodel_head_tab active'>
                        
                    {langv?.open_model?.text16}: {GlobalState.activemodel.modelName}
                    </div>
                </div>
               
                <div className='controler_top'> 
                    <div className='betta'>
                        <div className='betta_inside'>
                        {langv?.beta?.head_text}
                        </div>
                    </div>
                   
                    <div className='buttons_line_v'>
                        <div className={GlobalState.activemodel?.lastTrain?.deploymentState=='done'||GlobalState.activemodel?.lastTrain?.deploymentState=='in_progress'?'button_retrain inactive':'button_retrain'} onClick={()=>retrain()}>
                            <svg className='nonactive' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.15">
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" fill="#020203"/>
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" fill="black" fill-opacity="0.2"/>
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" fill="black" fill-opacity="0.2"/>
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" stroke="white" stroke-width="0.5"/>
                                </g>
                            </svg>
                            <svg className='def_icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6">
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" fill="#020203"/>
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" fill="black" fill-opacity="0.2"/>
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" fill="black" fill-opacity="0.2"/>
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" stroke="white" stroke-width="0.5"/>
                                </g>
                            </svg>
                            <svg className='hov_icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" fill="#020203"/>
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" fill="black" fill-opacity="0.2"/>
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" fill="black" fill-opacity="0.2"/>
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" stroke="white" stroke-width="0.5"/>
                            </svg>
                            {langv?.open_model?.text14}
                        </div>
                        
                        <div className={GlobalState.activemodel?.lastTrain?.deploymentState=='done'||GlobalState.activemodel?.lastTrain?.deploymentState=='in_progress'?'button_retrain inactive':'button_retrain'} onClick={()=>addmodel()}> 
                            <svg className='nonactive' width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.15">
                                <path d="M10.24 23.0799L16.48 12.9999H10.24V2.91992L4 12.9999H10.24V23.0799Z" stroke="#020203" stroke-width="1.44"/>
                                <path d="M10.24 23.0799L16.48 12.9999H10.24V2.91992L4 12.9999H10.24V23.0799Z" stroke="black" stroke-opacity="0.2" stroke-width="1.44"/>
                                <path d="M10.24 23.0799L16.48 12.9999H10.24V2.91992L4 12.9999H10.24V23.0799Z" stroke="black" stroke-opacity="0.2" stroke-width="1.44"/>
                                </g>
                            </svg>
                            <svg className='def_icon' width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6">
                                <path d="M10.24 23.0799L16.48 12.9999H10.24V2.91992L4 12.9999H10.24V23.0799Z" stroke="#020203" stroke-width="1.44"/>
                                <path d="M10.24 23.0799L16.48 12.9999H10.24V2.91992L4 12.9999H10.24V23.0799Z" stroke="black" stroke-opacity="0.2" stroke-width="1.44"/>
                                <path d="M10.24 23.0799L16.48 12.9999H10.24V2.91992L4 12.9999H10.24V23.0799Z" stroke="black" stroke-opacity="0.2" stroke-width="1.44"/>
                                </g>
                            </svg>

                            <svg  className='hov_icon' width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.24 23.0799L16.48 12.9999H10.24V2.91992L4 12.9999H10.24V23.0799Z" stroke="#020203" stroke-width="1.44"/>
                                <path d="M10.24 23.0799L16.48 12.9999H10.24V2.91992L4 12.9999H10.24V23.0799Z" stroke="black" stroke-opacity="0.2" stroke-width="1.44"/>
                                <path d="M10.24 23.0799L16.48 12.9999H10.24V2.91992L4 12.9999H10.24V23.0799Z" stroke="black" stroke-opacity="0.2" stroke-width="1.44"/>
                            </svg>
                            

                            {langv?.open_model?.text15}
                        </div>
                    </div>
                    <div className='addmodel_head_back' onClick={()=>setAddmodel0(false)}>
                    
                    <svg className='def_icon' width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#F5C538" stroke="#F5C538"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="#020203" stroke-linecap="round"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="#020203" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        </svg>
                        <svg className='hov_icon' width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#41A8B1" stroke="#41A8B1"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="#020203" stroke-linecap="round"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="#020203" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        </svg>
                    </div>
                   
                </div>
           </div>
           <div className='addmodel_body'>
                <div className='addmodel_fields'>
                    <div className='addmodel_fields_line'>
                        <div className='addmodel_fields_line_title'>{langv?.open_model?.text24}</div>
                        <div className='icon_disable' onClick={()=>setDisabletitle(!disabletitle)}>
                        {
                                !disabletitle?
                               
                                <svg className='def_icon' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.4">
                                    <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="#020203"/>
                                    <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="black" fill-opacity="0.2"/>
                                    <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="black" fill-opacity="0.2"/>
                                    <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="#020203"/>
                                    <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="black" stroke-opacity="0.2"/>
                                    <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="black" stroke-opacity="0.2"/>
                                    </g>
                                </svg>
                                
                                :
                                
                                <svg className='def_icon' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.4">
                                    <circle cx="7.1537" cy="9.46229" r="1.53846" fill="#020203"/>
                                    <circle cx="7.1537" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                                    <circle cx="7.1537" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                                    <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="#020203"/>
                                    <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="black" stroke-opacity="0.2"/>
                                    <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="black" stroke-opacity="0.2"/>
                                    </g>
                                </svg>
                                
                            }
                        </div>
                    </div>
                    {

                        disabletitle?
                        <></>
                        :
                        <>
                        <div className='addmodel_fields_line' style={{marginTop: "24px", position: "relative", zIndex: "99999999"}}>
                            <div className='addmodel_fields_col'>
                                <label>{langv?.open_model?.text6}</label>
                                <input
                                    id="source"
                                    type="text"
                                    placeholder={langv?.open_model?.text7}
                                    className={disablebut?"tra1":"tra2 tra3"}
                                    value={namemodal}
                                    disabled={disablebut}
                                    onChange={(event) =>setNamemodal(event.target.value)}
                                />
                            </div>
                            <div className='addmodel_fields_col'>
                                <label>{langv?.open_model?.text8}</label>
                               
                                <Select 
                                    components={{ DropdownIndicator }}
                                    options={optionsstep} 
                                    styles={customStyles} 
                                    classNamePrefix='filter'
                                    className={disablebut?"selectproduct tra1 ":"selectproduct tra2"}
                                    isSearchable={false} 
                                    isDisabled={disablebut}
                                    placeholder={<div className='selectplace active'>{GlobalState.activemodel?.lastTrain?.learningCyclesCount||langv?.open_model?.text9}</div>}
                                    onChange={(option) => setCountmodal(option.value)}
                                />
                            </div>
                            <div className='addmodel_fields_col' style={{position: "relative", zIndex: "999999999999999"}}>
                                <label>{langv?.open_model?.text10}</label>
                                <Select 
                                    components={{ DropdownIndicator }}
                                    options={optionstype} 
                                    styles={customStyles} 
                                    classNamePrefix='filter'
                                    className={disablebut?"selectproduct tra1 ":"selectproduct tra2"}
                                    isSearchable={false} 
                                    isDisabled={disablebut}
                                    placeholder={<div className='selectplace active'>{GlobalState.activemodel?.lastTrain?.learningType}</div>}
                                    onChange={(option) => setOptiontype(option.value)}
                                />
                            </div>
                        </div>
                        <div className='addmodel_fields_line' style={{marginTop: "20px"}}>
                            <div className='addmodel_fields_col'>
                                <label>{langv?.open_model?.text11}</label>
                                <Select 
                                    components={{ DropdownIndicator }}
                                    options={options} 
                                    styles={customStyles} 
                                    classNamePrefix='filter'
                                    className={disablebut?"selectproduct tra1 ":"selectproduct tra2"}
                                    isSearchable={false} 
                                    isDisabled={disablebut}
                                    placeholder={<div className='selectplace active'>{GlobalState.activemodel?.lastTrain?.featureDatasetName}</div>}
                                    onChange={(option) => setOptionmetrics(option.value)}
                                />
                            </div>
                            <div className='addmodel_fields_col'>
                                <label>{langv?.open_model?.text12}</label>
                                <Select 
                                    components={{ DropdownIndicator }}
                                    options={options} 
                                    styles={customStyles}
                                    classNamePrefix='filter' 
                                    className={disablebut?"selectproduct tra1 ":"selectproduct tra2"}
                                    isSearchable={false} 
                                    isDisabled={disablebut}
                                    placeholder={<div className='selectplace active'>{GlobalState.activemodel?.lastTrain?.targetDatasetName}</div>}
                                    onChange={(option) => changecategorytarget(option.value)}
                                />
                            </div>
                            <div className='addmodel_fields_col'>
                                <label>{langv?.open_model?.text13}</label>
                                <Select 
                                    components={{ DropdownIndicator }}
                                    options={optionfields} 
                                    styles={customStyles} 
                                    classNamePrefix='filter'
                                    className={disablebut?"selectproduct tra1 ":"selectproduct tra2"}
                                    isSearchable={false} 
                                    isDisabled={disablebut}
                                    placeholder={<div className='selectplace active'>{GlobalState.activemodel?.lastTrain?.targetColumnName}</div>}
                                    onChange={(option) => setOptiontargetfields(option.value)}
                                />
                            </div>
                        </div>
                        <div className='addmodel_fields_line' style={{marginBottom:"0px", marginTop: "20px"}}>
                           
                            {
                                load==0?
                                <>
                                    <div className='addmodel_fields_col'></div>
                                    <div className='addmodel_fields_col'>
                                        <div className='addmodel_button_line' >
                                            <div className='addmodel_but' onClick={()=>startmodel()}>
                                            {langv?.open_model?.text25}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='addmodel_fields_col'></div>
                                </>
                                :
                            
                                <div className='line_detailedeploy'>
                                    
                                    {
                                        GlobalState.activemodel?.lastTrain?.inputDatasetName||GlobalState.activemodel?.lastTrain?.outputDatasetName!=null?
                                        <>
                                            <label>{langv?.open_model?.text19}</label>
                                            <div className='detailedeploy'>
                                                
                                                {
                                                    GlobalState.activemodel?.lastTrain?.inputDatasetName!=null?
                                                    <>{langv?.open_model?.text20}: {GlobalState.activemodel?.lastTrain?.inputDatasetName}<br/></>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    GlobalState.activemodel?.lastTrain?.outputDatasetName!=null?
                                                    <>{langv?.open_model?.text21}: {GlobalState.activemodel?.lastTrain?.outputDatasetName}<br/></>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    GlobalState.activemodel?.lastTrain?.deploymentSchedule!=null?

                                                    <>{langv?.open_model?.text22} {GlobalState.activemodel?.lastTrain?.deploymentSchedule.split('T')[0]+" "+GlobalState.activemodel?.lastTrain?.deploymentSchedule.split('T')[1].split(".")[0]} UTC {GlobalState.activemodel?.lastTrain?.deploymentPeriodicity}</>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                                
                            }
                            
                        </div>
                        </>
                    }
                </div>
           </div>
           
           {
                load==1?
                <Loading text = {textloading}/>
                :
                    load==2?
                    <LoadingCompleted text = {textloadingcompleated}/>
                    : 
                        load==4?
                        <LoadingFail text = {textloadingcompleated}/>
                        :   
                            load==3?
                            <>
                            {
                                GlobalState.activemodel?.lastTrain?.learningType=="binary"?
                                <div className='blok_metrix'>
                                    <div className='addmodel_fields_line' style={{marginBottom:'0px'}}>
                                        <div className='addmodel_fields_line_title'>{langv?.open_model?.text23}</div>
                                        <div className='icon_disable' onClick={()=>setDisablebody(!disablebody)}>
                                        {
                                            !disablebody?
                                        
                                            <svg className='def_icon' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.4">
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="#020203"/>
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="black" fill-opacity="0.2"/>
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="black" fill-opacity="0.2"/>
                                                <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="#020203"/>
                                                <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="black" stroke-opacity="0.2"/>
                                                <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="black" stroke-opacity="0.2"/>
                                                </g>
                                            </svg>
                                            
                                            :
                                            
                                            <svg className='def_icon' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.4">
                                                <circle cx="7.1537" cy="9.46229" r="1.53846" fill="#020203"/>
                                                <circle cx="7.1537" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                                                <circle cx="7.1537" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                                                <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="#020203"/>
                                                <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="black" stroke-opacity="0.2"/>
                                                <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="black" stroke-opacity="0.2"/>
                                                </g>
                                            </svg>
                                            
                                        }
                                        </div>
                                    </div>
                                    {
                                        disablebody?
                                        <></>
                                        :
                                        <>
                                            <Accuracy/>
                                            <Contop/>
                                            <TabsTarget/>
                                            <Metric/>
                                            <Priview/>
                                        </>
                                    }
                                </div>
                                :
                                <>      
                                </>       
                            }
                            {
                                GlobalState.activemodel?.lastTrain?.learningType=="multiclass"?
                                <div className='blok_metrix'>
                                    <div className='addmodel_fields_line'>
                                        <div className='addmodel_fields_line_title'>{langv?.open_model?.text23}</div>
                                        <div className='icon_disable' onClick={()=>setDisablebody(!disablebody)}>
                                        {
                                            !disablebody?
                                        
                                            <svg className='def_icon' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.4">
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="#020203"/>
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="black" fill-opacity="0.2"/>
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="black" fill-opacity="0.2"/>
                                                <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="#020203"/>
                                                <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="black" stroke-opacity="0.2"/>
                                                <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="black" stroke-opacity="0.2"/>
                                                </g>
                                            </svg>
                                            
                                            :
                                            
                                            <svg className='def_icon' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.4">
                                                <circle cx="7.1537" cy="9.46229" r="1.53846" fill="#020203"/>
                                                <circle cx="7.1537" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                                                <circle cx="7.1537" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                                                <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="#020203"/>
                                                <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="black" stroke-opacity="0.2"/>
                                                <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="black" stroke-opacity="0.2"/>
                                                </g>
                                            </svg>
                                            
                                        }
                                            
                                        </div>
                                    </div>
                                    {
                                        disablebody?
                                        <></>
                                        :
                                        <>
                                            <Accuracy/>
                                            <Contop/>
                                            <TabsTarget/>
                                            <Metric/>
                                            <Priview/>
                                        </>
                                    }
                                    
                                    
                                </div>
                                :
                                <>
                                    
                                </>       
                            }
                            {
                                GlobalState.activemodel?.lastTrain?.learningType=="regression"?
                                <div className='blok_metrix'>
                                    <div className='addmodel_fields_line'>
                                        <div className='addmodel_fields_line_title'>{langv?.open_model?.text23}</div>
                                        <div className='icon_disable' onClick={()=>setDisablebody(!disablebody)}>
                                        {
                                            !disablebody?
                                        
                                            <svg className='def_icon' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.4">
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="#020203"/>
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="black" fill-opacity="0.2"/>
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="black" fill-opacity="0.2"/>
                                                <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="#020203"/>
                                                <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="black" stroke-opacity="0.2"/>
                                                <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="black" stroke-opacity="0.2"/>
                                                </g>
                                            </svg>
                                            
                                            :
                                            
                                            <svg className='def_icon' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.4">
                                                <circle cx="7.1537" cy="9.46229" r="1.53846" fill="#020203"/>
                                                <circle cx="7.1537" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                                                <circle cx="7.1537" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                                                <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="#020203"/>
                                                <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="black" stroke-opacity="0.2"/>
                                                <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="black" stroke-opacity="0.2"/>
                                                </g>
                                            </svg>
                                            
                                        }
                                        </div>
                                    </div>
                                    {
                                        disablebody?
                                        <></>
                                        :
                                        <>
                                            <Fourblock/>
                                            <TopField/>
                                            <Metric/>
                                            <Priview/>
                                        </>
                                    }
                                    
                                    
                                </div>
                                :
                                <>
                                </>       
                            }
                            </>
                            :
                            <></>
            }
           
        </div>
    );
})

export default Addmodels;
