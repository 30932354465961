import React, {useState} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import GlobalState, {changepopupworkspace, addactivedataset, addsegmentations, changesegmentationpage, changemikrosegmentations, changepopupdataset, changepopupdeleteWorkspace} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import Pool from "../autorize/UserPool";
import lang from "../../lang"

import Loading from '../common/loading';
import LoadingCompleted from '../common/loading_completed';
import LoadingFail from '../common/loading_fail';
const PopupInfo = observer(() =>{
    
    const [load, setLoad] = useState(0);
    const [wname, setWname] = useState(GlobalState.activedatasetSegment.nameSegment);
    const [datasetLine, setDatasetLine] = useState([]);
    const [datasetColumns, setDatasetColumns] = useState("");
    const [datasetRows, setDatasetRows] = useState("");
    const [datasetdatasetname, setDatasetdatasetname] = useState("");
    const [datasetfilekey, setDatasetfilekey] = useState("");
    const [datasetfilename, setDatasetfilename] = useState("");
    const [datasetkeycolumn, setDatasetkeycolumn] = useState("");
    const [datasetsource, setDatasetsource] = useState("");
    const [datasetstatus, setDatasetstatus] = useState("");
    const [datasetuploadtime, setDatasetuploadtime] = useState("");
    const [datasetuserid, setDatasetuserid] = useState("");
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en);
    const [masschange, setMasschange] = useState(0);

    const [textloading, setTextloading] = useState("Workspace Creation In progress");
    const [textloadingcompleated, setTextloadingcompleated] = useState("Workspace  Created");
    const [textloadingUpdate, setTextloadingUpdate] = useState("Workspace  Update");


    const handleRadioChange = (id, 
        Columns, 
        Rows, 
        dataset_name,
        file_key,
        file_name,
        key_column,
        source,
        status,
        upload_time,
        user_id) => {
        if(GlobalState.activedatasetSegment.nameSegment=="")
        { 
            

            if(datasetLine.indexOf(id) != -1)
            {
                var tunder =[];
                datasetLine.map((n)=>
                    n!=id?
                    tunder = [...tunder, n]
                    :
                    <></>
                )
                setDatasetLine(tunder); 
            }
            else{
                var tunder = [...datasetLine, id];
                setDatasetLine(tunder); 
            }

  
            



            setDatasetColumns(Columns);
            setDatasetRows(Rows);
            setDatasetdatasetname(dataset_name);
            setDatasetfilekey(file_key);
            setDatasetfilename(file_name);
            setDatasetkeycolumn(key_column);
            setDatasetsource(source);
            setDatasetstatus(status);
            setDatasetuploadtime(upload_time);
            setDatasetuserid(user_id);
        }
    }; 
    const massRadioChange = () => {
        var tunder = [];
        if(masschange==0){
            
            GlobalState.dataset?.map((n, id) =>
                {
                    tunder = [...tunder, n.id]; 
                }
            )
            setDatasetLine(tunder);
            setMasschange(1)
        }
        else
        {
            setDatasetLine(tunder);
            setMasschange(0)
        }
         
    }; 

    const updateGeneralSettings0 = async (n, name, workspace_key) => {

        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `segmentations/?key_file=`+n, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        
        if(response.status=="200")
        {
            const data = await response.json();
            console.log(data)

            if( data.segmentations!="")
            {
                var dataset = {
                    id: data._id,
                    idWorkspace: workspace_key,
                    nameSegment: name,
                    nameDataset: data.name,
                    Columns: "", 
                    Rows: "", 
                    file_key: data.key_file,
                    file_name: "",
                    segmentations: data.segmentations,
                    key_column: "",
                    source: "",
                    status: "",
                    upload_time: n.upload_time,
                    user_id: data.user_id,
                    tag: n.tag
                };
                addactivedataset(dataset);

                var segmentations = {
                    date_create: data.date_create,
                    key_file: data.key_file,
                    name: data.name,
                    segmentations: data.segmentations,
                    user_id: data.user_id,
                    _id: data._id,
                }
                addsegmentations(segmentations);
            }
            else
            {
                alert(data.status)
            }
            
        } 
    }

    const updateGeneralSettings = async () => {
        setLoad(1);
        if(GlobalState.activedatasetSegment.nameSegment=="")
        { 
            var token = localStorage.getItem("token");
            var workspace = {
                name: wname,
                dataset_key: datasetfilekey,
                status: "Active"
            };
            localStorage.setItem("countactivetime", Date.now());
            const response = await fetch(Domen.url + `segmentations/workspace`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(workspace),
            })
            if(response.status=="200")
            {
                const data = await response.json();
               setLoad(3)
                 
                
                setTimeout(function(){
                    updateGeneralSettings0(data?.workspace?.dataset_key, data?.workspace?.name,data?.workspace?._id)
                
                    changepopupworkspace(); 
                    setLoad(0)
                }, 2000)
            }
            else
            {
                const user = Pool.getCurrentUser();
                user.getSession((err, session) => {
                    if (err) {
                        console.error("Error fetching user session:", err);                       
                    } 
                    else 
                    {
                        if (!session) {
                            console.log("No session found");
                            return;
                        }
                        else
                        {
                            const refreshToken  = session.getRefreshToken();
                            user.refreshSession(refreshToken, (err, result) => {
                                const { accessToken } = result;
                                localStorage.setItem("token", accessToken.jwtToken);
                                updateGeneralSettings();
                            });
                        }
                        
                    }
                })
                setLoad(2)
                setTimeout(function(){
                    setLoad(0)
                }, 2000)
            }
        }
        else
        {
            var token = localStorage.getItem("token");
            var workspace = {
                new_name: wname,
            };
            localStorage.setItem("countactivetime", Date.now());
            const response = await fetch(Domen.url + `segmentations/workspace/`+ GlobalState.activedatasetSegment.idWorkspace, {
                method: 'PATCH',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(workspace),
            })
            if(response.status=="200")
            {
                setLoad(4)
                const data = await response.json();
                
                setTimeout(function(){
                    updateGeneralSettings0(GlobalState.activedatasetSegment.file_key, wname, GlobalState.activedatasetSegment.idWorkspace)
                    changepopupworkspace();  
                    setLoad(0)
                }, 2000)
            }
        }
    };
    const deleteGeneralSettings = async () => {
        changepopupworkspace()
        changepopupdeleteWorkspace()
    };
    
    const changepopupdatasetopen = () =>{
     
        changepopupdataset()
    }

    const next_page = async() =>{
        var a = [{title: wname, titletext: GlobalState.segmentationitems[0].titletext, list: GlobalState.segmentationitems[0].list}] 



        var token = localStorage.getItem("token");
            var workspace = {
                columnName: wname,
                datasets: datasetLine,
            };
            localStorage.setItem("countactivetime", Date.now());
            const response = await fetch(Domen.url + `portraits/table`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(workspace),
            })
            // test
            if(response.status=="202")
            {
                const data = await response.json();
                location.href = location.href
            }



        
        // changemikrosegmentations(a);
        // changesegmentationpage(2);
    }
    
    return (
        <div className="popupInfofon" >
            <div className="popupInfo addwork adw new_creat_segment" style={{background:"#fff", borderRadius: "12px",  paddingRight:" 20px", minWidth: "calc(100% - 120px)"}}>
                
                {
                                
                    load==1?
                    <Loading text = {textloading}/>
                    :
                    <></>
                }
                {
                    load==2?
                    <LoadingFail text = {textloadingcompleated}/>
                    :
                    <></>
                }
                {
                    load==3?
                    <LoadingCompleted text = {textloadingcompleated}/>
                    :
                    <></>
                    
                }
                {
                    load==4?
                    <LoadingCompleted text = {textloadingUpdate}/>
                    :
                    <></>
                    
                }
                {
                    load==0?
                    <>
                        
                        <div className='form-element' style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px", marginTop:"0px"}}>
                            <div className='linenamework'>
                                <span>Object ID name*</span>
                                <input
                                    id="workspacename"
                                    type="text"
                                    placeholder="Type Object ID name"
                                    className="pop-input_new_segment"
                                    value={wname}
                                    onChange={(event) => setWname(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className='datasetblock '>
                            <div className='title_datasetblock'>
                            {langv?.segmentation?.work_creation?.text5}
                            </div>
                            <div className='block_dataset_lines'>
                                <div className='datasethead'>
                                <div className='topfield field0 ff0' onClick={()=>massRadioChange()}>
                                    {
                                        masschange!=0?
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.20833 0.5C2.49004 0.5 1.80116 0.785341 1.29325 1.29325C0.785341 1.80116 0.5 2.49004 0.5 3.20833V12.7917C0.5 13.51 0.785341 14.1988 1.29325 14.7067C1.80116 15.2147 2.49004 15.5 3.20833 15.5H12.7917C13.51 15.5 14.1988 15.2147 14.7067 14.7067C15.2147 14.1988 15.5 13.51 15.5 12.7917V3.20833C15.5 2.49004 15.2147 1.80116 14.7067 1.29325C14.1988 0.785341 13.51 0.5 12.7917 0.5H3.20833ZM1.75 3.20833C1.75 2.40333 2.40333 1.75 3.20833 1.75H12.7917C13.5967 1.75 14.25 2.40333 14.25 3.20833V12.7917C14.25 13.1784 14.0964 13.5494 13.8229 13.8229C13.5494 14.0964 13.1784 14.25 12.7917 14.25H3.20833C2.82156 14.25 2.45063 14.0964 2.17714 13.8229C1.90365 13.5494 1.75 13.1784 1.75 12.7917V3.20833ZM12.4 5.73333C12.4614 5.67611 12.5107 5.60711 12.5448 5.53045C12.579 5.45378 12.5973 5.37102 12.5988 5.2871C12.6003 5.20318 12.5849 5.11983 12.5534 5.042C12.522 4.96418 12.4752 4.89348 12.4159 4.83414C12.3565 4.77479 12.2858 4.728 12.208 4.69656C12.1302 4.66513 12.0468 4.64969 11.9629 4.65117C11.879 4.65265 11.7962 4.67102 11.7196 4.70518C11.6429 4.73934 11.5739 4.78859 11.5167 4.85L6.33083 10.03L4.48333 8.18333C4.36485 8.07293 4.20815 8.01283 4.04623 8.01569C3.88431 8.01854 3.72983 8.08414 3.61531 8.19865C3.5008 8.31316 3.43521 8.46765 3.43235 8.62956C3.4295 8.79148 3.4896 8.94819 3.6 9.06667L5.8875 11.355C6.00469 11.472 6.16354 11.5378 6.32917 11.5378C6.49479 11.5378 6.65365 11.472 6.77083 11.355L12.4 5.73333Z" fill="#34C759"/>
                                        </svg>
                                        :
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.23679 1.33398H12.7606C13.2658 1.33398 13.7503 1.53466 14.1075 1.89188C14.4647 2.24909 14.6654 2.73357 14.6654 3.23875V12.7626C14.6654 13.2677 14.4647 13.7522 14.1075 14.1094C13.7503 14.4666 13.2658 14.6673 12.7606 14.6673H3.23679C2.73162 14.6673 2.24714 14.4666 1.88992 14.1094C1.53271 13.7522 1.33203 13.2677 1.33203 12.7626V3.23875C1.33203 2.73357 1.53271 2.24909 1.88992 1.89188C2.24714 1.53466 2.73162 1.33398 3.23679 1.33398Z" stroke="#C7C7CC" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    }
                                   
                                </div>
                                <div className='topfield field1'>{langv?.segmentation?.work_creation?.text6}</div>
                                <div className='topfield field2'>{langv?.segmentation?.work_creation?.text7}</div>
                                <div className='topfield field8 filtras'>
                                    Tag
                                </div>
                                <div className='topfield field3'>{langv?.segmentation?.work_creation?.text8}</div>
                                <div className='topfield field5'>{langv?.segmentation?.work_creation?.text10}</div>
                                <div className='topfield field4'>{langv?.segmentation?.work_creation?.text9}</div>
                                <div className='topfield field6'>{langv?.segmentation?.work_creation?.text11}</div>

                            </div>
                            <div className='line_body_lines'>
                                {
                                    
                                    GlobalState.dataset?.map((n, id)=>
                                      
                                        <div className={datasetLine.indexOf(n.id) != -1 ?'datasetbody newline active':'datasetbody newline'}>
                                    
                                            <div className='topfield field0 ff0' onClick={()=>handleRadioChange(n.id, n.columns, n.rows, n.dataset_name, n.file_key, n.file_name, n.key_column, n.source, n.status, n.upload_time,n.user_id)} >

                                                {datasetLine.indexOf(n.id) != -1?
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.20833 0.5C2.49004 0.5 1.80116 0.785341 1.29325 1.29325C0.785341 1.80116 0.5 2.49004 0.5 3.20833V12.7917C0.5 13.51 0.785341 14.1988 1.29325 14.7067C1.80116 15.2147 2.49004 15.5 3.20833 15.5H12.7917C13.51 15.5 14.1988 15.2147 14.7067 14.7067C15.2147 14.1988 15.5 13.51 15.5 12.7917V3.20833C15.5 2.49004 15.2147 1.80116 14.7067 1.29325C14.1988 0.785341 13.51 0.5 12.7917 0.5H3.20833ZM1.75 3.20833C1.75 2.40333 2.40333 1.75 3.20833 1.75H12.7917C13.5967 1.75 14.25 2.40333 14.25 3.20833V12.7917C14.25 13.1784 14.0964 13.5494 13.8229 13.8229C13.5494 14.0964 13.1784 14.25 12.7917 14.25H3.20833C2.82156 14.25 2.45063 14.0964 2.17714 13.8229C1.90365 13.5494 1.75 13.1784 1.75 12.7917V3.20833ZM12.4 5.73333C12.4614 5.67611 12.5107 5.60711 12.5448 5.53045C12.579 5.45378 12.5973 5.37102 12.5988 5.2871C12.6003 5.20318 12.5849 5.11983 12.5534 5.042C12.522 4.96418 12.4752 4.89348 12.4159 4.83414C12.3565 4.77479 12.2858 4.728 12.208 4.69656C12.1302 4.66513 12.0468 4.64969 11.9629 4.65117C11.879 4.65265 11.7962 4.67102 11.7196 4.70518C11.6429 4.73934 11.5739 4.78859 11.5167 4.85L6.33083 10.03L4.48333 8.18333C4.36485 8.07293 4.20815 8.01283 4.04623 8.01569C3.88431 8.01854 3.72983 8.08414 3.61531 8.19865C3.5008 8.31316 3.43521 8.46765 3.43235 8.62956C3.4295 8.79148 3.4896 8.94819 3.6 9.06667L5.8875 11.355C6.00469 11.472 6.16354 11.5378 6.32917 11.5378C6.49479 11.5378 6.65365 11.472 6.77083 11.355L12.4 5.73333Z" fill="#34C759"/>
                                                    </svg>
                                                    :
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.23679 1.33398H12.7606C13.2658 1.33398 13.7503 1.53466 14.1075 1.89188C14.4647 2.24909 14.6654 2.73357 14.6654 3.23875V12.7626C14.6654 13.2677 14.4647 13.7522 14.1075 14.1094C13.7503 14.4666 13.2658 14.6673 12.7606 14.6673H3.23679C2.73162 14.6673 2.24714 14.4666 1.88992 14.1094C1.53271 13.7522 1.33203 13.2677 1.33203 12.7626V3.23875C1.33203 2.73357 1.53271 2.24909 1.88992 1.89188C2.24714 1.53466 2.73162 1.33398 3.23679 1.33398Z" stroke="#C7C7CC" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                }
                                            </div>
                                            <div className='topfield field1'>
                                                <input
                                                    id="dataset"
                                                    type="text"
                                                    disabled="true"
                                                    placeholder=""
                                                    className="pop-input"
                                                    value={n.name}
                                                    //onChange={(event) => setDataset(event.target.value)}
                                                />
                                            </div>
                                            <div className='topfield field2'>
                                                <input
                                                    id="source"
                                                    type="text"
                                                    disabled="true"
                                                    placeholder=""
                                                    className="pop-input"
                                                    value={n.source}
                                                    //onChange={(event) => setSource(event.target.value)}
                                                />
                                            </div>
                                            <div className='topfield field8'>
                                                <div className='tags_dataset'>
                                                {
                                                    console.log(n.tag+"yt")
                                                }
                                                    {
                                                        n.tag!=""?
                                                            n.tag=="Predict input"?
                                                            <span style={{background: "rgba(235, 115, 27, 0.2)"}}>{n.tag}</span>
                                                            :
                                                            n.tag=="Portrait"?
                                                            <span style={{background: "rgba(245, 197, 56, 0.2)"}}>{n.tag}</span>
                                                            :
                                                            <span>{n.tag}</span>
                                                            
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                            <div className='topfield field3'>
                                                <input
                                                    id="lastchanges"
                                                    type="text"
                                                    disabled="true"
                                                    placeholder=""
                                                    className="pop-input"
                                                    value={n.last_changes}
                                                    //onChange={(event) => setLastchanges(event.target.value)}
                                                />
                                            </div>
                                            <div className='topfield field5'>
                                                <input
                                                    id="columns"
                                                    type="text"
                                                    disabled="true"
                                                    placeholder=""
                                                    className="pop-input"
                                                    value={n.columns}
                                                    //onChange={(event) => setColumns(event.target.value)}
                                                />
                                            </div>
                                            <div className='topfield field4'>
                                                <input
                                                    id="rows"
                                                    type="text"
                                                    disabled="true"
                                                    placeholder=""
                                                    className="pop-input"
                                                    value={n.rows}
                                                    //onChange={(event) => setRows(event.target.value)}
                                                />
                                            </div>
                                            <div className='topfield field6'>
                                                <input
                                                    id="objectid"
                                                    type="text"
                                                    disabled="true"
                                                    placeholder=""
                                                    className="pop-input"
                                                    value={n.object_ID}
                                                    //onChange={(event) => setObjectid(event.target.value)}
                                                />
                                            </div>
                                            
                                        </div>
                                       
                                    )
                                }
                            </div>
                            </div>
                            <div className='upload_dataset_line'>

                            
                                <div className='upload_dataset' onClick={()=>changepopupdatasetopen()}>
                                    
                                    <svg className='defalt_upload_dataset' width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#F5C538" stroke="#F5C538"/>
                                        <path d="M17.0326 10.0004L14.8533 12.1803C14.5383 12.4953 14.7614 13.0338 15.2069 13.0338H16.604V19.0664H17.4611V13.0338H18.8583C19.3037 13.0338 19.5268 12.4953 19.2119 12.1803L17.0326 10.0004Z" fill="#020203"/>
                                        <path d="M17.0326 10.0004L14.8533 12.1803C14.5383 12.4953 14.7614 13.0338 15.2069 13.0338H16.604V19.0664H17.4611V13.0338H18.8583C19.3037 13.0338 19.5268 12.4953 19.2119 12.1803L17.0326 10.0004Z" fill="black" fill-opacity="0.2"/>
                                        <path d="M17.0326 10.0004L14.8533 12.1803C14.5383 12.4953 14.7614 13.0338 15.2069 13.0338H16.604V19.0664H17.4611V13.0338H18.8583C19.3037 13.0338 19.5268 12.4953 19.2119 12.1803L17.0326 10.0004Z" fill="black" fill-opacity="0.2"/>
                                        <path d="M11 21.0003V21.8574H11.8571H17H22.1429H23V21.0003H11Z" fill="#020203"/>
                                        <path d="M11 21.0003V21.8574H11.8571H17H22.1429H23V21.0003H11Z" fill="black" fill-opacity="0.2"/>
                                        <path d="M11 21.0003V21.8574H11.8571H17H22.1429H23V21.0003H11Z" fill="black" fill-opacity="0.2"/>
                                    </svg>
                                    <svg className='hover_upload_dataset' width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#41A8B1" stroke="#41A8B1"/>
                                        <path d="M17.0326 9.99992L14.8533 12.1798C14.5383 12.4948 14.7614 13.0333 15.2069 13.0333H16.604V19.0659H17.4611V13.0333H18.8583C19.3037 13.0333 19.5268 12.4948 19.2119 12.1798L17.0326 9.99992Z" fill="#020203"/>
                                        <path d="M17.0326 9.99992L14.8533 12.1798C14.5383 12.4948 14.7614 13.0333 15.2069 13.0333H16.604V19.0659H17.4611V13.0333H18.8583C19.3037 13.0333 19.5268 12.4948 19.2119 12.1798L17.0326 9.99992Z" fill="black" fill-opacity="0.2"/>
                                        <path d="M17.0326 9.99992L14.8533 12.1798C14.5383 12.4948 14.7614 13.0333 15.2069 13.0333H16.604V19.0659H17.4611V13.0333H18.8583C19.3037 13.0333 19.5268 12.4948 19.2119 12.1798L17.0326 9.99992Z" fill="black" fill-opacity="0.2"/>
                                        <path d="M11 20.9998V21.8569H11.8571H17H22.1429H23V20.9998H11Z" fill="#020203"/>
                                        <path d="M11 20.9998V21.8569H11.8571H17H22.1429H23V20.9998H11Z" fill="black" fill-opacity="0.2"/>
                                        <path d="M11 20.9998V21.8569H11.8571H17H22.1429H23V20.9998H11Z" fill="black" fill-opacity="0.2"/>
                                    </svg>

                                    &nbsp;&nbsp;{langv?.segmentation?.work_creation?.text12}
                                </div>
                                <div  className={wname===''?'next_but':'next_but active'} onClick={()=>next_page()}>
                                    Next
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <></>
                }
            </div>
        </div>
    );
});

export default PopupInfo;
