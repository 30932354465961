import React, {useState, useEffect} from 'react';
import Select, { components } from 'react-select'
import { observer } from 'mobx-react'
import AttachmentBoxAnalitics from "./AttachmentBoxAnalitics";
import AttachmentBoxAnaliticsbig from "./AttachmentBoxAnaliticsbig";
import AttachmentBoxAnaliticsbigout from "./AttachmentBoxAnaliticsbigout";
import TableExcelAnalitics from "./tableExcelAnalitics";
import Loading from '../common/loading';
import LoadingCompleted from '../common/loading_completed';

import LoadingFail from '../common/loading_fail';
import './index.css';
import Papa from "papaparse";
import GlobalState, {changepopupaddAttachmentAnalitics, changeActiveModeldatasetPrewbigquerypredict, changePrewdatasetbigquerypredict, changepopupaddAttachmentbig, changeActiveModeldatasetPrewbigquery, changePrewdatasetbigquery, changeInputDataset, changeOutputDataset, changeInputDatasetName, changeOutputDatasetName} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"
import zIndex from '@material-ui/core/styles/zIndex';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const PopupInfo = observer(() =>{
    
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [attachments, setAttachments] = useState([]);
    const [nameDataset, setnameDataset] = useState("");
    const [nameDatasetout, setnameDatasetout] = useState("");
    const [nameDatasetprew, setnameDatasetprew] = useState("");
    const [predicate, setPredicate] = useState(false);
    const [nameDataset2, setnameDataset2] = useState("");
    const [objectid, setObjectid] = useState("");
    const [dataid, setDataid] = useState("");
    const [activebut, setActivebut] = useState(1);
    const [activebutOut, setActivebutOut] = useState(1);
    const [load, setLoad] = useState(0);
    const [file, setFile] = useState();
    const [save, setSave] = useState(false);
    const [typeBigquery, setTypeBigquery] = useState(1);
    const [typeCSWquery, setTypeCSWquery] = useState(1);
    const [typeBigqueryout, setTypeBigqueryout] = useState(1);
    const [typeBigquerytime, setTypeBigquerytime] = useState(false);
    const [statehead, setStatehead] = useState(1);
    const [textloading, setTextloading] = useState("Prediction  in Progress");
    const [textloadingcompleated, setTextloadingcompleated] = useState("Prediction Complete");
    const [optionmetrics, setOptionmetrics] = useState("");
    const [polden, setPolden] = useState(2);
    const [every, setEvery] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [timedata, setTimedata] = useState(false);

    const [inputdata, setInputdata] = useState(GlobalState.inputDatasetname);
    const [outputdata, setOutputdata] = useState(GlobalState.outputDatasetname);


    const [minut, setMinut] = useState("00");
    const [hour, setHour] = useState("01");

    let options = [];
    let cswoptions = [];
    let datepiker = [];
    let hours = [];
    let minuts = [{ value: "00", label:"00" },{ value: "15", label:"15" },{ value: "30", label: "30" },{ value: "45", label: "45" }];
    let days = [{ value: "daily", label:"Day" },{ value: "weekly", label:"Week" },{ value: "monthly", label: "Month" }]
    for(var i = 1; i<=12; i++)
    {
        hours = [...hours, { value: i, label: i }]
    }


    const checkmodel = async()=>{
        setTextloading("Prediction  in Progress <br/><br/> You can close this window <br/>without waiting for the result.")
        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `model/`+GlobalState.activemodel?.id+`/prediction/last`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        const data = await response.json();
        if(data.state=='failed')
        {
            setLoad(4);
        }
        else if(data.state=='finished')
        {
            activebutOut==2?
            setTextloadingcompleated("Processing the saving of deployment")
            :
            setTextloadingcompleated("Prediction Complete");
            
            setLoad(3);
            //setLoad(2);
        }  
        else{
            setTimeout(function(){
                checkmodel();
            }, 30000)
        }
    }

    const startmodel = async() =>{
        
        
        if(activebut==2&&typeCSWquery==1)
        {
           
           if(nameDataset!=""&&objectid!=''&&(timedata==true||(timedata==false&&dataid!='')))
           {
                setStatehead(3)
                setPredicate(true);
                setLoad(1);
                var token = localStorage.getItem("token");
                const formData = new FormData();
                formData.append('file', attachments[0].file);
                if(timedata==false){
                    formData.append('tag', "Predict input");
                }
                localStorage.setItem("countactivetime", Date.now());
                const response = await fetch( Domen.url + `dataset/csv/upload?object_id=`+objectid+'&name='+nameDataset, {
                    method: 'POST',
                    headers: {
                    'Authorization': token,
                    },
                    body: formData,
                });
                if(response.status>199&&response.status<399)
                {
                const data = await response.json();
                changeInputDataset(data.id)
                changeInputDatasetName(data.name)

                var product = {
                    inputDataset: data.id,
                };
                if(GlobalState.outputDataset!='')
                {
                    product = {
                        inputDataset: data.id,
                        outputDataset: GlobalState.outputDataset,
                    };
                }
                
                var token = localStorage.getItem("token");
                localStorage.setItem("countactivetime", Date.now());
                setTextloading("Prediction  in Progress <br/><br/> You can close this window <br/>without waiting for the result.");
                fetch(Domen.url + `model/`+GlobalState.activemodel?.id+`/prediction`, {
                    method: 'POST',
                    headers: {
                        'Authorization': token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(product),
                })
                .then(response => 
                {
                    if(response.status>199&&response.status<300)
                    {
                        
                        checkmodel()                       
                    }
                    else{
                        setLoad(3);
                    }
                    //setLoad(2);
                    //console.log(response)
                })
                .catch(error => {
                    console.error('There was an error with the fetch operation:', error);
                });


            }

            }
            else
            {
            setLoad(4)
            }
        }
        else
        {
            setStatehead(3)
            setPredicate(true);
            setLoad(1);
            var product = {
                inputDataset: GlobalState.inputDataset,
                //outputDataset: GlobalState.activemodel.lastTrain.featureDataset,
            };
            setTextloading("Prediction  in Progress <br/><br/> You can close this window <br/>without waiting for the result.")
            var token = localStorage.getItem("token");
            localStorage.setItem("countactivetime", Date.now());
            fetch(Domen.url + `model/`+GlobalState.activemodel?.id+`/prediction`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(product),
            })
            .then(response => 
            {
                if(response.status>199&&response.status<399)
                {
                    checkmodel()      
                    //setTextloadingcompleated("Processing the saving of deployment");
                    //setLoad(3);            
                }
                else{
                    setLoad(5);
                }
                //setLoad(2);
                //console.log(response)
            })
            .catch(error => {
                console.error('There was an error with the fetch operation:', error);
            });
        }
        
    }

    const startmodel2 = () =>{
        
        setLoad(1);

        
        var product = {
            inputDataset: GlobalState.inputDataset,
            outputDataset: GlobalState.outputDataset,
        };
        
        localStorage.setItem("countactivetime", Date.now());
        var token = localStorage.getItem("token");
        fetch(Domen.url + `model/`+GlobalState.activemodel?.id+`/prediction`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(product),
        })
        .then(response => 
        {
            if(response.status>199&&response.status<399)
            {
               
                setTextloadingcompleated("Processing the saving of deployment");
                setLoad(3);            
            }
            else{
                setLoad(5);
            }
            //setLoad(2);
            //console.log(response)
        })
        .catch(error => {
            console.error('There was an error with the fetch operation:', error);
        });
        
    }
    
    
    const changesavemodel = () =>{  
        var h = hour;
        if(polden==2)
        {
            h = h*1+12;
        }
        if(statehead>=3)
        {
            var d = new Date(startDate);
            var y = d.getFullYear();
            var m = d.getMonth()+1;
            var a = d.getDate();
            
            if(m<10)
            {
                m = "0"+m;
            }
            if(a<10)
            {
                a = "0"+a;
            }
            var product = {
                inputDataset: GlobalState.inputDataset,
                outputDataset: GlobalState.outputDataset,
                startTime: y+"-"+m+"-"+a+"T"+h+":"+minut+":00.682Z",
                periodicity: every
            };
            var token = localStorage.getItem("token");
            localStorage.setItem("countactivetime", Date.now());
            fetch(Domen.url + `model/`+GlobalState.activemodel?.id+`/deploy`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                  },
                body: JSON.stringify(product),
            })
            .then(response => {
                if(response.status>199&&response.status<300)
                {
                    //setStatehead(4); 
                    setTextloadingcompleated("Schedule save");
                    setStatehead(3.5);
                    setLoad(2);
                    if(statehead==5){
                        //changepopupaddAttachmentAnalitics()test

                    }               

                }
                else{
                    setLoad(5);
                }
                
            })
            .catch(error => {
                console.error('There was an error with the fetch operation:', error);
            })
           
        }
 
    }
    const activesave = (a) =>{
        setActivebut(a)
    }
    const activesaveOut = (a) =>{
        if(a==1)
        {
            if(activebut==1)
            {
                setActivebutOut(a)
            }
        }
        if(a==2)
        {
            if(activebut==2)
            {
                setActivebutOut(a)
            }
        }
        
    }

    async function uploadFile(fileurl){
        localStorage.setItem("countactivetime", Date.now());
        var token = localStorage.getItem("token");
        await fetch(fileurl, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        }).then(response => {
         
        })
    }

    useEffect(() => {
        uploadFile(file)
    }, [file]);

    const setStatehead0 = async(a) =>{

            setStatehead(a)
            if(a==1){
            setAttachments([]);
            setnameDataset("");
            setnameDatasetout("");
            setnameDatasetprew("");
            setPredicate(false);
            setnameDataset2("");
            setObjectid("");
            setDataid("");
            setActivebut(1);
            setActivebutOut(1);
            setLoad(0);
            setFile();
            setSave(false);
            setTypeBigquery(1);
            setTypeCSWquery(1);
            setTypeBigqueryout(1);
            setTypeBigquerytime(false);
            setStatehead(1);
            setOptionmetrics("");
            setPolden(2);
            setEvery("");
            }
            if(a==2.5){
                if(nameDataset!="")
                {
                    if(timedata==false){
                        var product = {
                            table: GlobalState.tablebigquery,
                            name: nameDataset,
                            objectId: objectid,
                            credentials: GlobalState.credentialbigquery,
                            dateColumnName: dataid,
                            tag: ["Predict input"]
                        };
                    }
                    else{
                        var product = {
                            table: GlobalState.tablebigquery,
                            name: nameDataset,
                            objectId: objectid,
                            credentials: GlobalState.credentialbigquery,
                            dateColumnName: dataid
                           
                        };
                    }
                    
                    var token = localStorage.getItem("token");
                    localStorage.setItem("countactivetime", Date.now());
                    const response = await fetch( Domen.url + `dataset/bigQuery/connect`, {
                        method: 'POST',
                        headers: {
                        'Authorization': token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(product),
                    });
                    
                    
                    if(response.status>199||response.status<399)
                    {
                    const data = await response.json();
                    changeInputDataset(data.id)
                    changeInputDatasetName(data.name)
                    }
                    else
                    {
                    //setLoad(5)
                    }
                }
            }
    }

    async function getLinkOutline(){
        
        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `model/`+GlobalState.activemodel?.id+`/prediction/last/generateDownloadLink`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();
            window.location.href=data.url;
        }
        else
        {
            setLoad(3)
        }
    }

    const openlinkoutput = ()=>{
        getLinkOutline()
    }

    GlobalState.dataset?.map((n, id)=>
        n.source=='bigquery'?
        options = [...options, { value: n.id, label: n.name }]
        :
        <></>
    )
    GlobalState.dataset?.map((n, id)=>
        n.source!='bigquery'?
        cswoptions = [...cswoptions, { value: n.id, label: n.name }]
        :
        <></>
    )
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '373px',
            height: '38px',
            color: 'rgba(245, 197, 56, 1)',
            border: '0px solid rgba(244, 245, 247, 1)',
            boxShadow: 'none',
            fontSize: 15,
        }),
        
        menu: (provided) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            width: '373px',
           
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer',
            color: 'rgba(245, 197, 56, 1)',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            paddingLeft: 0,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'rgba(255, 255, 255, 1)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(45, 50, 57, 1)', 
            },
            '&:active': {
                backgroundColor: 'rgba(45, 50, 57, 1))', 
            }
            }),
    };
    const customStyles2 = {
        control: (provided, state) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '264px',
            height: '38px',
            color: 'rgba(245, 197, 56, 1)',
            border: '0px solid rgba(244, 245, 247, 1)',
            boxShadow: 'none',
            fontSize: 15,
            position:'relative',
            zIndex: 1
        }),
        
        menu: (provided) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            width: '264px',
            paddingLeft: '20px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer',
            color: 'rgba(245, 197, 56, 1)',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            paddingLeft: 0,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'rgba(255, 255, 255, 1)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(45, 50, 57, 1)', 
            },
            '&:active': {
                backgroundColor: 'rgba(45, 50, 57, 1))', 
            }
            }),
    };
    const customStyles3 = {
        control: (provided, state) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '200px',
            height: '38px',
            color: 'rgba(245, 197, 56, 1)',
            border: '0px solid rgba(244, 245, 247, 1)',
            boxShadow: 'none',
            fontSize: 15,
            zIndex:9999999,
            position:'relataive'
        }),
        
        menu: (provided) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            width: '200px',

            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer',
            color: 'rgba(245, 197, 56, 1)',
            zIndex:9999,
            position:'absolute'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            paddingLeft: 0,
            color: state.isSelected ? 'rgba(245, 197, 56, 1)' : 'rgba(255, 255, 255, 1)',
            zIndex:9999,
            position:'relative',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(245, 197, 56, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(245, 197, 56, 1)', 
            },
            '&:active': {
                backgroundColor: 'rgba(245, 197, 56, 1)', 
            }
            }),
    };
    const customStyles4 = {
        control: (provided, state) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100px',
            height: '38px',
            color: 'rgba(245, 197, 56, 1)',
            border: '0px solid rgba(244, 245, 247, 1)',
            boxShadow: 'none',
            fontSize: 15,
            zIndex:9999999,
            position:'relataive'
        }),
        
        menu: (provided) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            width: '100px',

            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer',
            color: 'rgba(245, 197, 56, 1)',
            zIndex:9999,
            position:'absolute'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            paddingLeft: 0,
            color: state.isSelected ? 'rgba(245, 197, 56, 1)' : 'rgba(255, 255, 255, 1)',
            zIndex:9999,
            position:'relative',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(245, 197, 56, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(245, 197, 56, 1)', 
            },
            '&:active': {
                backgroundColor: 'rgba(245, 197, 56, 1)', 
            }
            }),
    };
    const DropdownIndicator = props => {
        return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="12.5" r="2" fill="white"/>
                        <path d="M1 9.5L9.24242 1.5L17 9.5" stroke="white"/>
                    </svg>
                    : 
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 7 4.5)" fill="white"/>
                        <path d="M1 5.5L9.24242 13.5L17 5.5" stroke="white"/>
                    </svg>

                }
            </components.DropdownIndicator>
        )
        );
    };

    const adddatasetprewWorkspacecheck = async (a) => {

        setOptionmetrics(a);

        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `dataset/`+a+`/preview`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        
        if(response.status=="200")
        {
            const data = await response.json();
            changeActiveModeldatasetPrewbigquery(data.sample.slice(0, 3));
            changePrewdatasetbigquery(data);

            changeActiveModeldatasetPrewbigquerypredict(data.sample.slice(0, 3));
            changePrewdatasetbigquerypredict(data);

 
            setStatehead(2);
            changeInputDataset(data.id);
            changeInputDatasetName(data.name);
            setInputdata(data.name)
        } 
        else
        {
            setLoad(5)
        }
    }
    const adddatasetprewWorkspacecheck1 = async (a) => {

        setOptionmetrics(a);
       
        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `dataset/`+a+`/preview`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        
        if(response.status=="200")
        {
            const data = await response.json();
            changeActiveModeldatasetPrewbigquery(data.sample.slice(0, 3));
            changePrewdatasetbigquery(data);
            changeActiveModeldatasetPrewbigquerypredict(data.sample.slice(0, 3));
            changePrewdatasetbigquerypredict(data);
   
            changeOutputDataset(data.id)
            changeOutputDatasetName(data.name)
            setOutputdata(data.name)
            if(typeBigquerytime)
            {
                //setStatehead(4);

                setTextloadingcompleated("Processing the saving of deployment");
                setStatehead(3.5);
                setLoad(1)
                startmodel2();
                
                
            }
            else{
                setStatehead(5);
            }
            
            //changesavemodel()
        } 
        else
        {
            setLoad(5)
        }
    }

    const setTypeBigquery0 = (a)=>
    {
        setTypeBigquery(a);
    }
    const setTypeCSWquery0 = (a)=>
    {
        setTypeCSWquery(a);
    }
    const setTypeBigqueryout0 = (a)=>
    {
        setTypeBigqueryout(a);
        setLoad(1)
    }
    
    const setOptionmetrics0 = (a)=>
    {
        adddatasetprewWorkspacecheck(a)
    }
    const setOptionmetrics1 = (a)=>
    {
        adddatasetprewWorkspacecheck1(a)
    }
    
    return (
        <div className="popupInfofon analitdeploy">
            <div className="popupInfo addwork attach analitdeployscroll" style={{maxWidth: "calc(900px - 80px)"}}>
                <div className='top-element'>
                    <div className='namepopup'>
                        {langv?.deploy?.text2}
                    </div>
                    {/* <div className='separator'></div> */}
                    <div className='buttonspopup' style={{minWidth: "80px"}}>
                        {
                            statehead==5?
                            <div className={statehead>4&&every!=''?'buttonsave active':'buttonsave'} style={{marginRight: "8px"}} onClick={()=>changesavemodel()}>{langv?.deploy?.text3}</div>
                            :
                            <></>
                        }
                        {/* <div className={statehead>4&&every!=''?'buttonsave active':'buttonsave'} onClick={()=>changesavemodel()}>{langv?.deploy?.text3}</div> */}
                        <div className='buttonexit' onClick={changepopupaddAttachmentAnalitics}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='stage_atach'>
                {
                    <>
                        
                        <div className={statehead>1?'buton_stage inactive':statehead==1?'buton_stage active':<></>} onClick={()=>setStatehead0(1)} style={{width:"175px"}}>
                        {langv?.deploy?.text4}
                        </div>
                        {
                            statehead>1?
                            <div className={statehead>2?'buton_stage inactive':statehead==2?'buton_stage active':'buton_stage'} onClick={()=>setStatehead0(2)} style={{width:"175px"}}>
                                {langv?.deploy?.text5}
                            </div>
                            :
                            <div className={statehead>2?'buton_stage inactive':statehead==2?'buton_stage active':'buton_stage'}  style={{width:"175px"}}>
                                {langv?.deploy?.text5}
                            </div>
                        }
                      
                        {
                            statehead>2?
                                activebut==1?
                                <div className={statehead>2.5?'buton_stage inactive':statehead==2.5?'buton_stage active':'buton_stage'} onClick={()=>setStatehead0(2.5)} style={{width:"175px"}}>
                                    {langv?.deploy?.text6}
                                </div>
                                :
                                <></>
                                :
                                activebut==1?
                                <div className={statehead>2.5?'buton_stage inactive':statehead==2.5?'buton_stage active':'buton_stage'} style={{width:"175px"}}>
                                    {langv?.deploy?.text6}
                                </div>
                                :
                                <></>
                        }
                        
                        {
                            statehead>2.5?
                                activebut==2?
                                <div className={statehead>3?'buton_stage inactive':statehead==3?'buton_stage active':'buton_stage'} onClick={()=>setStatehead0(3)}>
                                    {langv?.deploy?.text7}
                                </div>
                                :
                                <></>
                                :
                                activebut==2?
                                <div className={statehead>3?'buton_stage inactive':statehead==3?'buton_stage active':'buton_stage'} >
                                    {langv?.deploy?.text7}
                                </div>
                                :
                                <></>
                        }
                        {
                            statehead>2.5?
                                activebut==1?
                                <div className={statehead>5?'buton_stage inactive':statehead==5?'buton_stage active':'buton_stage'} onClick={()=>setStatehead0(5)}>
                                    {langv?.deploy?.text8}
                                </div>
                                :
                                <></>
                                :
                                activebut==1?
                                <div className={statehead>5?'buton_stage inactive':statehead==5?'buton_stage active':'buton_stage'}>
                                    {langv?.deploy?.text8}
                                </div>
                                :
                                <></>
                        }
                        
                    </>
                }
                </div>
                <div className={activebut==1?'adddataset-element bigdata center':'adddataset-element'}>
                    {
                        statehead==1&&load==0?
                        <>
                            <div className="tableexcel_select" style={{width:"100%", marginBottom:"24px"}}>
                                <div className={activebut==1?"but_depluyment active":"but_depluyment"} onClick={()=>activesave(1)}>
                                {langv?.deploy?.text9}
                                </div>
                                <div className={activebut==2?"but_depluyment active":"but_depluyment"} onClick={()=>activesave(2)}>
                                {langv?.deploy?.text10}
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                    {
                        statehead==2.5?
                        <div className="tableexcel_select" style={{width:"100%"}}>
                            <div className={activebut==1?activebutOut==1?"but_depluyment active":"but_depluyment":"but_depluyment inactive"} onClick={()=>activesaveOut(1)}>
                            {langv?.deploy?.text9}
                            </div>
                            <div className={activebut==2?activebutOut==2?"but_depluyment active":"but_depluyment":"but_depluyment inactive"} onClick={()=>activesaveOut(2)}>
                            {langv?.deploy?.text10}
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {
                        statehead==2.5?
                            <>
                                {
                                    activebutOut==2?
                                    <div className="but_next_stepanalitics_line">
                                        <div className="but_next_stepanalitics" onClick={()=>startmodel()}>{langv?.deploy?.text25}</div>
                                    </div>
                                    :
                                    <></>
                                }
                                   
                            </>
                        :
                        <></>
                    }
                    {
                        statehead==3.5?
                        <div className='loading_container'>
                        <div className='loading_lines' style={{marginTop: "0px"}}>
                            {
                                activebut==1?
                                    <div className='bq_load'>
                                    {
                                        load==1?
                                        <Loading text = {textloading}/>
                                        :
                                            load==4?
                                            <LoadingFail text = {textloadingcompleated}/>
                                            :
                                            <>
                                            <LoadingCompleted text = {textloadingcompleated}/>
                                           
                                                {/* <div onClick={()=>openlinkoutput()} className="but_next_stepanalitics_line yui">
                                                    <div className="but_next_stepanalitics downloads">{langv?.deploy?.text11}</div>
                                                </div> */}
                                              
                                            
                                            </>
                                    }
                                    </div>
                                    :
                                    <div className='csv_load'>
                                    {
                                        load==1?
                                        <Loading text = {textloading}/>
                                        :
                                            load==4?
                                            <LoadingFail text = {textloadingcompleated}/>
                                            :
                                            <>
                                            <LoadingCompleted text = {textloadingcompleated}/>
                                           
                                                <div onClick={()=>openlinkoutput()} className="but_next_stepanalitics_line yui">
                                                    <div className="but_next_stepanalitics downloads">{langv?.deploy?.text11}</div>
                                                </div>
                                              
                                            
                                            </>
                                    }
                                    </div>
                            }
                        </div>  
                        </div>
                        :
                        <></> 
                    }
                    {
                        statehead==3?
                        <div className='loading_container'>
                        <div className='loading_lines' style={{marginTop: "0px"}}>
                            {
                                activebut==1?
                                    <div className='bq_load'>
                                    {
                                        load==1?
                                        <Loading text = {textloading}/>
                                        :
                                            load==4?
                                            <LoadingFail text = {textloadingcompleated}/>
                                            :
                                            <>
                                            <LoadingCompleted text = {textloadingcompleated}/>
                                           
                                                {/* <div onClick={()=>openlinkoutput()} className="but_next_stepanalitics_line yui">
                                                    <div className="but_next_stepanalitics downloads">{langv?.deploy?.text11}</div>
                                                </div> */}
                                              
                                            
                                            </>
                                    }
                                    </div>
                                    :
                                    <div className='csv_load'>
                                    {
                                        load==1?
                                        <Loading text = {textloading}/>
                                        :
                                            load==4?
                                            <LoadingFail text = {textloadingcompleated}/>
                                            :
                                            <>
                                            <LoadingCompleted text = {textloadingcompleated}/>
                                           
                                                <div onClick={()=>openlinkoutput()} className="but_next_stepanalitics_line yui">
                                                    <div className="but_next_stepanalitics downloads">{langv?.deploy?.text11}</div>
                                                </div>
                                              
                                            
                                            </>
                                    }
                                    </div>
                            }
                        </div>  
                        </div>
                        :
                        <></> 
                    }                     
                    {
                        activebut==1?
                            !attachments[0]?.file && (statehead==1) && load==0?

                                <>
                                    <div className='typedatasets'>
                                        <span onClick={()=>setTypeBigquery0(1)}>
                                            {
                                                typeBigquery==1?
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12.1484" cy="12.3008" r="4" fill="#33A753"/>
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="#33A753" stroke-width="1.5"/>
                                                </svg>
                                                :
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.6">
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="white" stroke-width="1.5"/>
                                                    </g>
                                                </svg>
                                            }
                                            
                                            {langv?.deploy?.text26}
                                        </span>
                                        <span onClick={()=>setTypeBigquery0(2)}>
                                            {
                                                typeBigquery==2?
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12.1484" cy="12.3008" r="4" fill="#33A753"/>
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="#33A753" stroke-width="1.5"/>
                                                </svg>
                                                :
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.6">
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="white" stroke-width="1.5"/>
                                                    </g>
                                                </svg>
                                            }
                                            {langv?.deploy?.text27}
                                        </span>
                                    </div>
                                    {
                                        typeBigquery==1?
                                        <input id="website" type="text" placeholder="Type Big Query Project ID" class="pop-input datasetname" value={nameDatasetprew} onChange={(event) => setnameDatasetprew(event.target.value)} style={{marginBottom:"24px"}}></input>
                                        :
                                        <Select 
                                            components={{ DropdownIndicator }}
                                            options={options} 
                                            styles={customStyles} 
                                            classNamePrefix='filter'
                                            className='selectproduct'
                                            isSearchable={false} 
                                            placeholder={<div className='selectplace active'>{langv?.deploy?.text15}</div>}
                                            onChange={(option) => setOptionmetrics0(option.value)}
                                        />
                                    }
                                </>
                            :
                            <> </>
                        :
                        <></>
                    }
                    {
                        activebut==2?
                            !attachments[0]?.file && (statehead==1)?
                                <>
                                    <div className='typedatasets' style={{marginBottom:"0px"}}>
                                        <span onClick={()=>setTypeCSWquery0(1)}>
                                            {
                                                typeCSWquery==1?
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12.1484" cy="12.3008" r="4" fill="#33A753"/>
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="#33A753" stroke-width="1.5"/>
                                                </svg>
                                                :
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.6">
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="white" stroke-width="1.5"/>
                                                    </g>
                                                </svg>
                                            }
                                            
                                            New  CSV Table ID
                                        </span>
                                        <span onClick={()=>setTypeCSWquery0(2)}>
                                            {
                                                typeCSWquery==2?
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12.1484" cy="12.3008" r="4" fill="#33A753"/>
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="#33A753" stroke-width="1.5"/>
                                                </svg>
                                                :
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.6">
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="white" stroke-width="1.5"/>
                                                    </g>
                                                </svg>
                                            }
                                            Select Input Table from Datasets
                                        </span>
                                    </div>
                                    {
                                        typeCSWquery==1?
                                        <></>
                                        :
                                        <Select 
                                            components={{ DropdownIndicator }}
                                            options={cswoptions} 
                                            styles={customStyles} 
                                            classNamePrefix='filter'
                                            className='selectproduct'
                                            isSearchable={false} 
                                            placeholder={<div className='selectplace active'>{langv?.deploy?.text15}</div>}
                                            onChange={(option) => setOptionmetrics0(option.value)}
                                        />
                                    }
                                </>
                            :
                            <></>
                            :
                        <></>
                    }
                    {
                        activebutOut==1?
                            !attachments[0]?.file && (statehead==2.5)?

                                <>
                                    <div className='typedatasets' style={{marginTop:"24px"}}>
                                        <span onClick={()=>setTypeBigqueryout0(1)}>
                                            {
                                                typeBigqueryout==1?
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12.1484" cy="12.3008" r="4" fill="#33A753"/>
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="#33A753" stroke-width="1.5"/>
                                                </svg>
                                                :
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.6">
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="white" stroke-width="1.5"/>
                                                    </g>
                                                </svg>
                                            }
                                            
                                            {langv?.deploy?.text13}
                                        </span>
                                        <span onClick={()=>setTypeBigqueryout0(2)}>
                                            {
                                                typeBigqueryout==2?
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12.1484" cy="12.3008" r="4" fill="#33A753"/>
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="#33A753" stroke-width="1.5"/>
                                                </svg>
                                                :
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.6">
                                                    <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="white" stroke-width="1.5"/>
                                                    </g>
                                                </svg>
                                            }
                                            {langv?.deploy?.text28}
                                            
                                        </span>
                                    </div>
                                    {
                                        typeBigqueryout==1?
                                        <div className='line_vvod_anali'>
                                            <input id="website" type="text" placeholder="Type Big Query Project ID" class="pop-input datasetname" value={nameDatasetout} onChange={(event) => setnameDatasetout(event.target.value)}></input>
                                            <div className='onetime_but' onClick={()=>setTypeBigquerytime(!typeBigquerytime)}>
                                                {
                                                    typeBigquerytime?
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="12.1484" cy="12.3008" r="4" fill="#33A753"/>
                                                        <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="#33A753" stroke-width="1.5"/>
                                                    </svg>
                                                    :
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g opacity="0.6">
                                                        <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="white" stroke-width="1.5"/>
                                                        </g>
                                                    </svg>
                                                }
                                                {langv?.deploy?.text29}
                                               
                                            </div>
                                        </div>
                                        :
                                        <div className='line_vvod_anali'>
                                            <Select 
                                                components={{ DropdownIndicator }}
                                                options={options} 
                                                styles={customStyles} 
                                                classNamePrefix='filter'
                                                className='selectproduct'
                                                isSearchable={false} 
                                                placeholder={<div className='selectplace active'>{langv?.deploy?.text30}</div>}
                                                onChange={(option) => setOptionmetrics1(option.value)}
                                            />
                                            <div className='onetime_but' onClick={()=>setTypeBigquerytime(!typeBigquerytime)}>
                                                {
                                                    typeBigquerytime?
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="12.1484" cy="12.3008" r="4" fill="#33A753"/>
                                                        <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="#33A753" stroke-width="1.5"/>
                                                    </svg>
                                                    :
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g opacity="0.6">
                                                        <path d="M11.1484 3.87813C11.7672 3.52087 12.5296 3.52087 13.1484 3.87813L18.9427 7.22343C19.5615 7.5807 19.9427 8.24095 19.9427 8.95548L19.9427 15.6461C19.9427 16.3606 19.5615 17.0209 18.9427 17.3781L13.1484 20.7234C12.5296 21.0807 11.7672 21.0807 11.1484 20.7234L5.35421 17.3781C4.73541 17.0209 4.35421 16.3606 4.35421 15.6461L4.35421 8.95548C4.35421 8.24095 4.73541 7.5807 5.35421 7.22343L11.1484 3.87813Z" stroke="white" stroke-width="1.5"/>
                                                        </g>
                                                    </svg>
                                                }
                                                {langv?.deploy?.text29}
                                            </div>
                                        </div>
                                    }

                                </>
                            :
                            <> </>
                        :
                        <></>
                    }
                    {
                        statehead==1&&typeBigquery==1?
                        <>
                            {
                                activebut!=0?
                                    activebut==2?
                                        typeCSWquery==1?
                                        <>
                                        <div style={{marginBottom: "50px", minWidth: "100%"}}></div>
                                        <AttachmentBoxAnalitics {...{ attachments, setAttachments, setStatehead }} />
                                        </>
                                        :
                                        <></>
                                    :
                                    <AttachmentBoxAnaliticsbig {...{ attachments, setAttachments, setStatehead, nameDatasetprew, load, setLoad }} />
                                :
                                <></>
                            }
                        </>
                        :
                        <></>
                    }
                    {
                        statehead==2.5&&activebutOut==1?
                        <>
                            {
                                typeBigqueryout==1?
                                    
                                <AttachmentBoxAnaliticsbigout {...{ attachments, setAttachments, setStatehead, nameDatasetout, typeBigquerytime, save, setTextloadingcompleated, setLoad }} />
                                :
                                <></>
                            }
                        </>
                        :
                        <></>
                    }
                    {
                        statehead==2?
                            <>
                                <TableExcelAnalitics {...{ attachments, save, nameDataset, setnameDataset, objectid, setObjectid, setStatehead, dataid, setDataid, activebut, typeBigquery, typeCSWquery, setLoad, timedata, setTimedata }}/>
                                {
                                    activebut==1?
                                    <div className="but_next_stepanalitics_line">
                                        <div className="but_next_stepanalitics" onClick={()=>setStatehead0(2.5)}>{langv?.deploy?.text24}</div>
                                    </div>
                                    :
                                    <div className="but_next_stepanalitics_line">
                                        <div className="but_next_stepanalitics" onClick={()=>startmodel()}>{langv?.deploy?.text25}</div>
                                    </div>
                                }
                                
                            </>
                            :
                            <></>
                    }
                    {
                        statehead==5?
                            <div className='shedule'>
                                <div className='shedule_line1'>
                                    <input  type="text" placeholder="Input Table" class="pop-input datasetname" style={{width: "320px"}} value={GlobalState.inputDatasetname} />
                                    <input  type="text" placeholder="Output Table" class="pop-input datasetname" style={{width: "320px", marginLeft:"20px"}} value={GlobalState.outputDatasetname} />
                                </div>
                                <div className='shedule_line2'>
                                    <div className='shedule_line2_col1'>
                                        {langv?.deploy?.text31}
                                    </div>
                                    <div className='shedule_line2_col2'>
                                        <input id="website" type="text" placeholder="1" class="input_every" value={"1"} onChange={(event) => setnameDataset(event.target.value)}></input>
                                    </div>
                                    <div className='shedule_line2_col3'>
                                        <Select 
                                            components={{ DropdownIndicator }}
                                            options={days} 
                                            styles={customStyles3} 
                                            classNamePrefix='filter'
                                            className='selectproduct'
                                            isSearchable={false} 
                                            placeholder="Select every"
                                            onChange={(option) => setEvery(option.value)}
                                        />
                                        
                                    </div>
                                </div>
                                <div className='shedule_line3'>
                                    <div className='date_shedule'>
                                       
                                        <div className='shedule_line3_col1'>
                                        {langv?.deploy?.text32}
                                        </div>
                                        <div className='shedule_line3_col2'>
                                            <DatePicker selected={startDate} minDate={new Date().toISOString().split('T')[0]} dateFormat="YYYY-MM-dd" onChange={(date) => setStartDate(date)} />
                                        </div>
                                    </div>
                                    <div className='time_shedule'>
                                        <div className='shedule_line3_col4'>
                                        {langv?.deploy?.text33}
                                        </div>
                                        <div className='shedule_line3_col5'>
                                            <Select 
                                                components={{ DropdownIndicator }}
                                                options={hours} 
                                                styles={customStyles4} 
                                                classNamePrefix='filter'
                                                className='selectproduct'
                                                isSearchable={false} 
                                                placeholder={<div className='selectplace active'>01</div>}
                                                onChange={(option) => setHour(option.value)}
                                            />
                                        </div>
                                        <div className='shedule_line3_col4'>
                                        {langv?.deploy?.text34}
                                        </div>
                                        <div className='shedule_line3_col5'>
                                            <Select 
                                                components={{ DropdownIndicator }}
                                                options={minuts} 
                                                styles={customStyles4} 
                                                classNamePrefix='filter'
                                                className='selectproduct'
                                                isSearchable={false} 
                                                placeholder={<div className='selectplace active'>00</div>}
                                                onChange={(option) => setMinut(option.value)}
                                            />
                                        </div>

                                        <div className='checkpolden'>
                                            <span className={polden==1?"active":<></>} onClick={()=>setPolden(1)}>AM</span>
                                            <span className={polden==2?"active":<></>} onClick={()=>setPolden(2)}>PM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                </div>
            </div>
        </div>
    );
});

export default PopupInfo;
