import React, {useState} from "react";
import { toBase64 } from "../../fuse/utils";
import { DropzoneAreaBase } from "material-ui-dropzone";
import GlobalState, {changeActiveModeldatasetPrewbigquery, changePrewdatasetbigquery, changeLoadSegmentation, changesegmentationpage, changeActiveModeldatasetPrewbigquerypredict,  changePrewdatasetbigquerypredict,  changecredentialbigquery, changetablebigquery, changeInputDataset} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import Papa from "papaparse";
import lang from "../../lang"
import Loading from '../common/loading';
import LoadingCompleted from '../common/loading_completed';
import LoadingFail from '../common/loading_fail';


export default function AttachmentBox({ attachments, setAttachments, setStatehead, nameDatasetprew, load, setLoad }) {
  const sizeInMB = (sizeInBytes) => {
    return (sizeInBytes / (1024 * 1024)).toFixed(2);
  };
  
  const handleFileDelete = (file, arg2, arg3) => {};
  const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
  const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
  const [textloading, setTextloading] = useState("Uploading");
  const [textloadingcompleated, setTextloadingcompleated] = useState("Uploaded");
  
  const handleFilesChange = async (files) => {
    if (!files.length === 0) {
      return;
    }

    const filesToUpload = [];

    for (const file of files) {


      const fileContent = await toBase64(file);
      const fileExtension = file.name.split(".").pop();
      const fileName = file.name;
      const fileSize = sizeInMB(file.size);

      filesToUpload.push({
        file_content: fileContent,
        file_extension: fileExtension,
        file_name: fileName,
        file_path: fileName,
        file_size: fileSize,
        file_type: 2,
        file: file,
      });
    }
    setAttachments(filesToUpload);
  
  };

  const adddatasetprewWorkspacecheck = async (a) => {
    setTextloadingcompleated('Processing the saving of deployment')
    var token = localStorage.getItem("token");
    changeInputDataset("");
    var name = nameDatasetprew.replace(" ", "");
    var product = {
        name: "test",
        table: name,
        credentials: JSON.parse(a)
    };
    //setLoad(3)
    
    setTimeout(function(){
      changesegmentationpage(10)
    }, 2000)
    // const response = await fetch(Domen.url + `portraits/table/deploy/biguery`, {
    //     method: 'POST',
    //     headers: {
    //       'Authorization': token,
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json',
    //   },
    //     body: JSON.stringify(product),
    // })
    
    // if(response.status=="201")
    // {
    //     const data = await response.json();
        
    //     setLoad(3)
    //     changeLoadSegmentation(4)
    //     setTimeout(function(){
    //       changeLoadSegmentation(0)
    //       changesegmentationpage(1)
    //     }, 2000)
    //     //changesegmentationpage(10)
    // } 
    // else
    // {
      
    //     setLoad(4)
     
    // }
}

 
  const onDrop = async(e)=> {
    if(nameDatasetprew!='')
    {
      setLoad(1)
      const fileReader = new FileReader();
      console.log(e)
      fileReader.readAsText(e[0]);

      fileReader.onload = () => {
        adddatasetprewWorkspacecheck(fileReader.result);
      };
    }
  };


  return (
    <div className={nameDatasetprew==''?"my-14 px-10 zeras preloadbigdataset bq_load gray":"my-14 px-10 zeras preloadbigdataset bq_load zeras"} style={{minHeight: "360px !important"}}>
      {
        load==1?
        <div style={{marginTop:"150px"}}>
          <Loading text = {textloading}/>
        </div>
       
        :
        load==2?
        <LoadingCompleted text = {textloadingcompleated}/>
        :
        load==4?
        <LoadingFail text = {textloadingcompleated}/>
        :
        <DropzoneAreaBase
          initialFiles={attachments}
          acceptedFiles={['.csv, .json']}
          filesLimit={1}
          maxFileSize={209715200}
          clearOnUnmount={false}
          dropzoneClass="min-h-88"
          onDelete={handleFileDelete}
          onChange={handleFilesChange}
          dropzoneText={dropzoneText(nameDatasetprew)}
          showPreviewsInDropzone={false}
          showPreviews={false}
          Icon="none"
          //onAdd={(fileObjs) => onAdd(fileObjs)}
          onDrop={(e) => onDrop(e)}
        />
      }
      {/* <div className="flex flex-wrap">
        {attachments.map((val, index) => (
          <SingleFile deleteFile={() => deleteFile(index)} {...val} />
        ))}
      </div> */}
    </div>
  );
}

const dropzoneText = (nameDatasetprew) => {
  var langv = lang.en
  return (
    <>
   
      <div className="text-center my-16 dropanddrag">
        <div className="uploadiconline">
          <div className="uploadicon">
            {
              nameDatasetprew==''?
              <FileIcon />
              :
              <FileIcon2 />
            }
          </div>
        </div>
        
        <div className={nameDatasetprew==''?"title_dropanddrag inactive":"title_dropanddrag"}  >
         {langv?.dataset?.popupadddataset?.text4}
        </div>
        <p className={nameDatasetprew==''?"text_dropanddrag inactive":"text_dropanddrag"}>
        {langv?.dataset?.popupadddataset?.text6}
        </p>
        <div className="buttom_dropanddrag_line">
          <div className={nameDatasetprew==''?"buttom_dropanddrag inactive":"buttom_dropanddrag"}>{langv?.dataset?.popupadddataset?.text7}</div>
        </div>
        
      </div>
    </>
  );
};



const FileIcon2 = () => (
  <>
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9992 0.642365L9.89062 9.64347L11.9106 11.4722L18.5706 5.5435V27.5439H21.4278V5.5435L28.0849 11.4696L30.1078 9.64347L19.9992 0.642365Z" fill="#F5C538"/>
      <path d="M0 38.1002V40.6436H2.85714H20H37.1429H40V38.1002H0Z" fill="#F5C538"/>
    </svg>
  </>
);

const FileIcon = () => (
  <>
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
      <path d="M19.9992 0.142365L9.89062 9.14347L11.9106 10.9722L18.5706 5.0435V27.0439H21.4278V5.0435L28.0849 10.9696L30.1078 9.14347L19.9992 0.142365Z" fill="#F4F5F7"/>
      <path d="M0 37.6002V40.1436H2.85714H20H37.1429H40V37.6002H0Z" fill="#F4F5F7"/>
      </g>
  </svg>
  </>
);

