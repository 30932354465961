import React, {useState, useEffect} from "react";
import Select, { components } from 'react-select'
import { observer } from 'mobx-react';
import Papa from "papaparse";
import GlobalState, {adddataset, changeInputDataset, changeiddatasetinputbigquery, changeInputDatasetName} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const TableExcelAnalitics = observer(({attachments, save, nameDataset,  setnameDataset, objectid, setObjectid, setStatehead, dataid, setDataid, activebut, typeBigquery, typeCSWquery, setLoad, timedata, setTimedata}) =>{

  
  const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
  const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
  const [tableexel, setTableexel] = useState([]);
  const [tableexelmap, setTableexelmap] = useState([]);
  const [tableexelcol, setTableexelcol] = useState(0);
  const [tableexelrow, setTableexelrow] = useState(0);
  
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => { 
    if(attachments[0]?.file){
        Papa.parse(attachments[0]?.file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              setTableexel(results.data[0])
              setTableexelrow(results.data.length);
              setTableexelmap(results.data.slice(0,3));
            },
        });
    }    
  }, [attachments]);
  useEffect(() => { 
    setTableexelcol(Object.keys(tableexel).length);
    var iter = 0;
    var obj_id = "";
    Object.entries(tableexel).map(([key, value]) =>
      {
        iter===0?obj_id=key:<></>
        iter++
      }
    )
    // setObjectid(obj_id);
  }, [tableexelmap]);
  

  

  let options = [];
  let options2 = [];
  
  
  activebut==2?
  Object.entries(tableexel).map(([key, value]) => 
    options = [...options, { value: key, label: key }]
  )
  :
  Object.entries(GlobalState.activemodeldatasetprewbigquery[0]).map(([key, value]) => 
    options = [...options, { value: key, label: key }]
  )
  




  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: 'rgba(45, 50, 57, 1)',
      display: 'flex',
      flexWrap: 'nowrap',
      width: '178px',
      height: '38px',
      color: 'rgba(245, 197, 56, 1)',
      border: '0 !important',
      borderRadius: "8px",
      marginRight:"20px",
      boxShadow: 'none',
      '&:hover': {
        border: '0 !important',
        },
      '&:focus': {
          border: '0 !important',
      },
      '&::placeholder': {
          border: '0 !important',
      }
    }),
    
    menu: (provided) => ({
      ...provided,
      background: 'rgba(45, 50, 57, 1)',
      width: '178px',
      marginTop: '-7px',
      boxShadow: 'none',
      cursor: 'pointer',
      paddingTop: "0px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingBottom: "10px",
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: 15,
        color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'white',
        backgroundColor: 'rgba(255, 255, 255, 0)', 
        '&:hover': {
            color: 'rgba(235, 115, 27, 1)',
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0)', 
        },
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 0)', 
        }
      }),
};
  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            {
            props.selectProps.menuIsOpen ? 
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="12" r="2" fill="white"/>
                    <path d="M1 9L9.24242 1L17 9" stroke="white"/>
                </svg>
                : 
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 7 4.5)" fill="white"/>
                    <path d="M1 5.5L9.24242 13.5L17 5.5" stroke="white"/>
                </svg> 
            }
        </components.DropdownIndicator>
      )
    );
  };

  const setStatehead0 = async()=>{
    
    if(typeCSWquery==2)
    {
      setStatehead(3)
    }
    else
    {
      if(activebut==2&&nameDataset!=''&&objectid!='')
      {
        var token = localStorage.getItem("token");
     
        const formData = new FormData();
        formData.append('file', attachments[0].file);
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch( Domen.url + `dataset/csv/upload?object_id=`+objectid+'&name='+nameDataset, {
            method: 'POST',
            headers: {
              'Authorization': token,
            },
            body: formData,
        });
        if(response.status>199&&response.status<399)
        {
          const data = await response.json();
          changeInputDataset(data.id)
          changeInputDatasetName(data.name)
        }
        else
        {
          setLoad(5)
        }
        
          
        
        setStatehead(3)
      }
      else
      {


        if(typeBigquery==1&&nameDataset!=''&&objectid!='')
        {
            var token = localStorage.getItem("token");
            var d = new Date(startDate);
            var y = d.getFullYear();
            var m = d.getMonth();
            var a = d.getDate();
              
            if(m<10)
            {
              m = "0"+m;
            }
            if(a<10)
            {
              a = "0"+a;
            }

            var product = {
                table: GlobalState.tablebigquery,
                name: nameDataset,
                objectId: objectid,
                credentials: GlobalState.credentialbigquery,
                dateColumnName: dataid,
            };
            localStorage.setItem("countactivetime", Date.now());
            const response = await fetch( Domen.url + `dataset/bigQuery/connect`, {
                method: 'POST',
                headers: {
                  'Authorization': token,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(product),
            });
            
            
            if(response.status>199||response.status<399)
            {
              const data = await response.json();
              changeInputDataset(data.id)
              changeInputDatasetName(data.name)
            }
            else
            {
              setLoad(5)
            }
              
            
            setStatehead(3)
        }
        else{
          setStatehead(3)
        }
      }
    }

    
    
  }

  return (
    <div className="tableexcel_line">
      <div className="tableexcel_head">
       
        
        {
            activebut==1?
              typeBigquery==1?
              <>
              <div className="tableexcel_select place" >
                <input
                    id="website"
                    type="text"
                    placeholder={langv?.deploy?.text22}
                    className={timedata?"pop-input datasetname active":"pop-input datasetname"}
                    value={nameDataset}
                    onChange={(event) => setnameDataset(event.target.value)}
                />
                <div className="svitchtime">
                  <div className="svitchtime_text">{langv?.deploy?.text35}</div>
                  <div className={timedata?"svitchtime_butt butr1":"svitchtime_butt butr1 active"} onClick={()=>setTimedata(false)}>
                  {langv?.deploy?.text36}
                  </div>
                  <div className={timedata?"svitchtime_butt butr2 active":"svitchtime_butt butr2"} onClick={()=>setTimedata(true)}>
                  {langv?.deploy?.text37}
                  </div>
                </div>
                <Select 
                      components={{ DropdownIndicator }}
                      options={options} 
                      styles={customStyles} 
                      classNamePrefix='filter'
                      className={timedata?'selectproduct active':'selectproduct'}
                      isSearchable={false} 
                      placeholder={<div className='selectplace'>{langv?.deploy?.text38}</div>}
                      onChange={(option) => setObjectid(option.value)}
                  />
                  {
                    timedata?<></>:
                    <Select 
                        components={{ DropdownIndicator }}
                        options={options} 
                        styles={customStyles} 
                        classNamePrefix='filter'
                        className='selectproduct'
                        isSearchable={false} 
                        placeholder={<div className='selectplace'>{langv?.deploy?.text23}</div>}
                        onChange={(option) => setDataid(option.value)}
                    />
                  }
                  
                
              </div>
              
              </>
              :
              <></>
            :
            <></>
          }
          {
            activebut==2?
              typeCSWquery==1?
              <>
              
              <div className="tableexcel_select place" >
                <input
                    id="website"
                    type="text"
                    placeholder={langv?.deploy?.text22}
                    className={timedata?"pop-input datasetname active":"pop-input datasetname"}
                    value={nameDataset}
                    onChange={(event) => setnameDataset(event.target.value)}
                />
                <div className="svitchtime">
                  <div className="svitchtime_text">{langv?.deploy?.text35}</div>
                  <div className={timedata?"svitchtime_butt butr1":"svitchtime_butt butr1 active"} onClick={()=>setTimedata(false)}>
                  {langv?.deploy?.text36}
                  </div>
                  <div className={timedata?"svitchtime_butt butr2 active":"svitchtime_butt butr2"} onClick={()=>setTimedata(true)}>
                  {langv?.deploy?.text37}
                  </div>
                </div>
                <Select 
                      components={{ DropdownIndicator }}
                      options={options} 
                      styles={customStyles} 
                      classNamePrefix='filter'
                      className={timedata?'selectproduct active':'selectproduct'}
                      isSearchable={false} 
                      placeholder={<div className='selectplace'>{langv?.deploy?.text38}</div>}
                      onChange={(option) => setObjectid(option.value)}
                  />
                  {
                    timedata?<></>:
                    <Select 
                        components={{ DropdownIndicator }}
                        options={options} 
                        styles={customStyles} 
                        classNamePrefix='filter'
                        className='selectproduct'
                        isSearchable={false} 
                        placeholder={<div className='selectplace'>{langv?.deploy?.text23}</div>}
                        onChange={(option) => setDataid(option.value)}
                    />
                  }
                  
                
              </div>
              <div className="params_dataset" style={{justifyContent: "space-between"}}>
                <div className="tablevklad" style={{justifyContent: "space-between"}} dangerouslySetInnerHTML={{ __html: langv?.deploy?.text20 }}>
                 
        
                </div>
                <div className="line1_params_dataset">
                    <div className="param1_title_dataset">
                    {langv?.deploy?.text18}
                    </div>
                    <div className="param1_val_dataset">
                    {tableexelrow||0}
                    </div>
                    <div className="param2_title_dataset">
                    {langv?.deploy?.text17}
                    </div>
                    <div className="param2_val_dataset">
                        {tableexelcol||0}
                    </div>
                    
                </div>
              </div>
              </>
              :
              <>
              
              <div className="params_dataset" style={{justifyContent: "space-between"}}>
                <div className="tablevklad" style={{justifyContent: "space-between"}} dangerouslySetInnerHTML={{ __html: (langv?.deploy?.text20 + " "+ GlobalState.inputDatasetname) }}>
                  
                </div>
                <div className="line1_params_dataset">
                    <div className="param1_title_dataset">
                    {langv?.deploy?.text18}
                    </div>
                    <div className="param1_val_dataset">
                      {GlobalState.prewdatasetbigquery.rowsCount||0}
                    </div>
                    <div className="param2_title_dataset">
                    {langv?.deploy?.text17}
                    </div>
                    <div className="param2_val_dataset">
                        {GlobalState.prewdatasetbigquery.columnsCount||0}
                    </div>
                    
                </div>
              </div>
              </>
            :
            <></>
          }
          
          {
           activebut==1?
            typeBigquery==1?
            <div className="params_dataset" style={{justifyContent: "flex-end"}}>
              <div className="tablevklad" style={{justifyContent: "space-between"}} dangerouslySetInnerHTML={{ __html: langv?.deploy?.text20 }}>
              
              </div>
              <div className="line1_params_dataset" style={{textTransform: "capitalize", justifyContent: "flex-end"}}>
                <div className="param1_title_dataset">
                {langv?.deploy?.text18}
                </div>
                <div className="param1_val_dataset">
                    {GlobalState.prewdatasetbigquery.rowsCount||0}
                </div>
                <div className="param2_title_dataset">
                    {langv?.deploy?.text17}
                </div>
                <div className="param2_val_dataset">
                    {GlobalState.prewdatasetbigquery.columnsCount||0}
                </div>
                
              </div>
            </div>
            :
            <div className="tableexcel_head" style={{marginTop: "0px"}}>
              <div className="params_dataset" style={{justifyContent: "space-between"}}>
                <div className="tablevklad" style={{justifyContent: "space-between"}} dangerouslySetInnerHTML={{ __html: (langv?.deploy?.text20 + " "+ GlobalState.prewdatasetbigquery.name) }}>
              
                </div>
                <div className="line1_params_dataset">
                    <div className="param1_title_dataset">
                    {langv?.deploy?.text18}
                    </div>
                    <div className="param1_val_dataset">
                      {GlobalState.prewdatasetbigquery.rowsCount}
                    </div>
                    <div className="param2_title_dataset">
                    {langv?.deploy?.text17}
                    </div>
                    <div className="param2_val_dataset">
                        {GlobalState.prewdatasetbigquery.columnsCount}
                    </div>
                    
                </div>
              </div>
            </div>
            :<></>
        }
            
            
      </div>
      {
        tableexelmap.length>0?
        
        <div className="tableexcel_body">
          <table>
          <thead>
              <tr>
                {
                  Object.entries(tableexel).map(([key, value]) =>
                    <th>{key}</th>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                tableexelmap?.map((n, id)=>
                  <tr>
                    {
                      Object.entries(n).map(([key, value]) =>
                        <td>{value.slice(0,10)}{value.length>15?"...":<></>}</td>
                      )
                    }
                  </tr>
                )
              }
            </tbody>
            
          </table>
        </div>
        :
        
        <div className="tableexcel_body rt">
          
        <table>
        <thead>
            <tr>
            {
                GlobalState.activemodeldatasetprewbigquery.length>0?
                Object.entries(GlobalState.activemodeldatasetprewbigquery[0]).map(([key, value]) =>
                  <th>{key}</th>
                )
                :
                <></>
              }
            </tr>
          </thead>
          <tbody>
          {
              GlobalState.activemodeldatasetprewbigquery.length>0?
              GlobalState.activemodeldatasetprewbigquery?.map((n, id)=>
                  <tr>
                    {
                      n!=null?
                      Object.entries(n).map(([key, value]) =>
                        <td>{value}</td>
                      )
                      :
                      <></>
                    }
                  </tr>
                )
                :
                <></>
            }
          </tbody>
          
        </table>
        </div>
      }
      
      {
        activebut==1?
        
        <div className="params_dataset" style={{justifyContent: "space-between", marginTop: "24px"}}>
          <div className="tablevklad" style={{justifyContent: "space-between"}} dangerouslySetInnerHTML={{ __html: (langv?.deploy?.text19 + " "+ GlobalState.activemodel.lastTrain.featureDatasetName) }}>
          
          </div>
          <div className="line1_params_dataset" style={{textTransform: "capitalize", justifyContent: "flex-end"}}>
            <div className="param1_title_dataset">
            {langv?.deploy?.text18}
            </div>
            <div className="param1_val_dataset">
                {GlobalState.datasetinmodel.rowsCount||0}
            </div>
            <div className="param2_title_dataset">
                {langv?.deploy?.text17}
            </div>
            <div className="param2_val_dataset">
                {GlobalState.datasetinmodel.columnsCount||0}
            </div>
            
          </div>
        </div>
        :
        <div className="tableexcel_head" style={{marginTop: "0px"}}>
          <div className="params_dataset" style={{justifyContent: "space-between"}}>
            <div className="tablevklad" style={{justifyContent: "space-between"}} dangerouslySetInnerHTML={{ __html: (langv?.deploy?.text19 + " " + GlobalState.activemodel.lastTrain.featureDatasetName) }}>
                             
            </div>
            <div className="line1_params_dataset">
                <div className="param1_title_dataset">
                {langv?.deploy?.text18}
                </div>
                <div className="param1_val_dataset">
                  {GlobalState.datasetinmodel.rowsCount||0}
                </div>
                <div className="param2_title_dataset">
                {langv?.deploy?.text17}
                </div>
                <div className="param2_val_dataset">
                    {GlobalState.datasetinmodel.columnsCount||0}
                </div>
                
            </div>
          </div>
        </div>
      }
        
      {
        activebut==1?
        <div className="tableexcel_body">
          
          <table>
          <thead>
              <tr>
              {
                 
                  Object.entries(GlobalState.datasetinmodel?.sample.slice(0, 3)[0]).map(([key, value]) =>
                    <th>{key}</th>
                  )
                  
                }
              </tr>
            </thead>
            <tbody>
            {
               
                GlobalState.datasetinmodel?.sample.slice(0, 3)?.map((n, id)=>
                    <tr>
                      {
                        n!=null?
                        Object.entries(n).map(([key, value]) =>
                          <td>{value}</td>
                        )
                        :
                        <></>
                      }
                    </tr>
                  )
                  
              }
            </tbody>
            
          </table>
        </div>
        
        :
        
        typeCSWquery==2?
        <div className="tableexcel_body" >
          <table>
            <thead>
              <tr>
                {
                  GlobalState.activemodeldatasetprew.length>0?
                  Object.entries(GlobalState.activemodeldatasetprew[0]).map(([key, value]) =>
                    <th>{key}</th>
                  )
                  :
                  <></>
                }
              </tr>
            </thead>
            <tbody>
              {
                GlobalState.activemodeldatasetprew.length>0?
                GlobalState.activemodeldatasetprew?.map((n, id)=>
                    <tr>
                      {
                        n!=null?
                        Object.entries(n).map(([key, value]) =>
                          <td>{value}</td>
                        )
                        :
                        <></>
                      }
                    </tr>
                  )
                  :
                  <></>
              }
            </tbody>
          </table>
        </div>
        :
        <div className="tableexcel_body" >
          <table>
            <thead>
              <tr>
                {
                  GlobalState.activemodeldatasetprew.length>0?
                  Object.entries(GlobalState.activemodeldatasetprew[0]).map(([key, value]) =>
                    <th>{key}</th>
                  )
                  :
                  <></>
                }
              </tr>
            </thead>
            <tbody>
              {
                GlobalState.activemodeldatasetprew.length>0?
                GlobalState.activemodeldatasetprew?.map((n, id)=>
                    <tr>
                      {
                        n!=null?
                        Object.entries(n).map(([key, value]) =>
                          <td>{value}</td>
                        )
                        :
                        <></>
                      }
                    </tr>
                  )
                  :
                  <></>
              }
            </tbody>
          </table>
        </div>
      }
      
     
     
    </div>
  );
})

export default TableExcelAnalitics;

