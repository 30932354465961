import React, {useState, useEffect} from 'react';
import './SegmentationPage.css';
import { observer } from 'mobx-react'
import Sidebar from '../../components/sidebar/index.js';
import logoBig from '../../assets/logo-big.svg'
import Segment from '../../components/segmentation/index.js'
import Dataset from '../../components/dataset/index.js'
import PopupaddWorkspace from '../../components/segmentation/popupAddWorkspace.js';
import PopupaddSegmentation from '../../components/segmentation/popupAddSegmentation.js';
import GlobalState from "../../stores/GlobalState.js";
import PopupAddDataset from '../../components/dataset/popupAddDataset.js';
import PopupAttachment from '../../components/dataset/popupAttachment.js';
import PopupDeployment from '../../components/dataset/popupDeployment.js';
import PopupaddSegment from '../../components/segmentation/popupAddSegment.js'
import PopupDeleteColumn from '../../components/segmentation/popupDeleteColumn.js'
import PopupDeleteWorkspace from '../../components/segmentation/popupDeleteWorkspace.js';
import {Helmet} from "react-helmet";
import Beta from '../../components/Beta/index.js';
import UserPool from '../../components/autorize/UserPool';

const Segmentation = observer(() => {
    const [holdBlock, setholdBlock] = useState(true);
    const [datasegment, setDatasegment] = useState(1);
    const [user, setUser]=useState("");
    const changemenu = () =>{
        setholdBlock(false)
    }
    window.onbeforeunload = () => {
        setholdBlock(true)
    }
    const token = localStorage.getItem("token");
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const cognitoUser = UserPool.getCurrentUser();
            setUser(cognitoUser.username)
        } 
    }, []);
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    return (
        <>
            {
                langmenu=="de"?
                <Helmet>
                    <title>Bind Genius — Mikrosegmentierung der Kundenbasis</title>
                    <meta name="description" content="Bind Genius — Mikrosegmentierung basierend auf der Vorhersage des Kundenverhaltens durch Machine Learning: persönliche Empfehlungen, Kaufwahrscheinlichkeit, Abwanderungsrisiko und mehr." />
                </Helmet>
                :
                <Helmet>
                    <title>Bind Genius — Customer Base Micro-segmentation</title>
                    <meta name="description" content="Bind Genius — Micro-segmentation based on customer behavior prediction using Machine Learning: personalized recommendations, purchase propensity, churn risk, and more." />
                </Helmet>
            }
            <Sidebar/>
            {
            
            user=="admin"||user=="test_team_2"||user=="test_team_3"||user=="test"||user=="m.bahmat"||user=="m.tsvyd"?
            <>
                
                <div className="segmentation hold">
                
                    <div className='body_segment'>
                        {GlobalState.numbersubmenu===1?<Segment/>:<Dataset/>}
                    </div>
                </div>
                {GlobalState.ispopupaddworkspaceShow?<PopupaddWorkspace />:<></>}
                {GlobalState.ispopupaddSegmentationShow?<PopupaddSegmentation/>:<></>}
                {GlobalState.ispopupaddSegmentShow?<PopupaddSegment/>:<></>}
                {GlobalState.ispopupadddatasetShow?<PopupAddDataset/>:<></>}
                {GlobalState.ispopupaddAttachmentShow?<PopupAttachment/>:<></>}
                {GlobalState.ispopupDeploymentShow?<PopupDeployment/>:<></>}
                {GlobalState.ispopupdeleteWorkspaceShow?<PopupDeleteWorkspace/>:<></>}
                {GlobalState.ispopupdeleteColumnShow?<PopupDeleteColumn/>:<></>}
            </>
            :
            <div className="main-page">
                <Beta/>
            </div>
        }
        </>
    );
})

export default Segmentation;

