import React, {useState} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import { changepopupdeleteProduct, deletepopupproduct} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"

const PopupDeleteProduct = observer(({id, name, tokensCount}) =>{


    const deleteproductline = async (id, name) => {
        
      
        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        fetch(Domen.url + `general_settings/product_tool?product_tool_name=`+name, {
            method: 'DELETE',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            //changepopupdeleteProduct()
            window.location.href=window.location.href
        })
        .catch(error => {
            console.error('There was an error with the fetch operation:', error);
        });
        
    };
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    return (
        <div className="popupInfofon ">
            <div className="popupInfo deleteProduct">
                <div className='top-element'>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className='buttonexit' onClick={changepopupdeleteProduct}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='form-element'>
                    <div className='titlePopupDelete' dangerouslySetInnerHTML={{ __html: langv?.groupsettings?.popupdeleteproduct?.text1 }}>
                        
                    </div>
                    <div className='buttonsPopupDelete'>
                        <div className='buttonPopupDelete' onClick={changepopupdeleteProduct}>
                            {langv?.groupsettings?.popupdeleteproduct?.text2}
                        </div>
                        <div className='buttonPopupDelete' onClick={() =>deleteproductline(id, name)}>
                            {langv?.groupsettings?.popupdeleteproduct?.text3}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PopupDeleteProduct;
