import React, {useEffect} from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import UserPool from './components/autorize/UserPool';
import "./App.css";
import MainPage from "./page/MainPage/MainPage";
import {Account} from "./components/autorize/Account";
import LoginPage from "./page/LoginPage/LoginPage";
import GSetingsPage from "./page/GroupSettingsPage/GSettingsPage";
import SegmentationPage from "./page/SegmentationPage/SegmentationPage";
import SegmentationDatasetPage from "./page/SegmentationDatasetPage/SegmentationDatasetPage";
import Campaigns from "./page/CampaignsPage/CampaignsPage";
import Settings from "./page/SettingsPage/SettingsPage";
import Analytics from "./page/Analytics/Analytics";
import Datasets from "./page/Datasets/Datasets";
import {Helmet} from "react-helmet";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";
import favicon from "../src/assets/favicon.ico"

function App() {
    const token = localStorage.getItem("token");
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const cognitoUser = UserPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession((err, session) => {
                    if (err) {
                        console.error("Error fetching user session:", err);                       
                    } 
                    else 
                    {
                        if (!session) {
                            console.log("No session found");
                            return;
                        }
                        else
                        {
                            var countactivetime = localStorage.getItem("countactivetime");
                            if(countactivetime!=undefined)
                            {
                                var huri = (countactivetime/1000)+600;
                                var huriN = (Date.now()/1000);
                                const currentPath = window.location.pathname;
                                if(huriN>huri)
                                {
                                    localStorage.setItem("token", "");
                                    localStorage.setItem("countactivetime", Date.now());
                                    currentPath.includes("login")?
                                    <></>
                                    :
                                    window.location.href = "/login";
                                }
                            }
                        }
                        
                    }
                });
            }
            else
            {
                console.log("User no authenticated");
                return;
            }
        } else {
            console.log("User not authenticated");
           
            if (window.location.pathname !== '/login') {
                window.location.href = "/login";
            }
        }
        document.addEventListener('keydown', function(event) 
        {

            var countactivetime = localStorage.getItem("countactivetime");
            if(countactivetime!=undefined)
            {
                var huri = (countactivetime/1000)+600;
                var huriN = (Date.now()/1000);
                const currentPath = window.location.pathname;
                if(huriN>huri)
                {
                    localStorage.setItem("token", "");
                    localStorage.setItem("countactivetime", Date.now());
                    currentPath.includes("login")?
                    <></>
                    :
                    window.location.href = "/login";
                }
                else
                {
                    localStorage.setItem("countactivetime", Date.now());
                }
            }
           
            
        });
        document.addEventListener("mouseover", (event) => {
            var countactivetime = localStorage.getItem("countactivetime");
            if(countactivetime!=undefined)
            {
                var huri = (countactivetime/1000)+600;
                var huriN = (Date.now()/1000);
                const currentPath = window.location.pathname;
                if(huriN>huri)
                {
                    localStorage.setItem("token", "");
                    localStorage.setItem("countactivetime", Date.now());
                    currentPath.includes("login")?
                    <></>
                    :
                    window.location.href = "/login";
                }
                else
                {
                    localStorage.setItem("countactivetime", Date.now());
                }
            }
        });
    }, []);
    
    // useEffect(() => {
    //     const script = document.createElement('script');
      
    //     script.src = "//js-eu1.hs-scripts.com/144786253.js";
    //     script.async = true;
    //     script.defer = true;
    //     script.id="hs-script-loader"
    //     script.type="text/javascript"
      
    //     document.body.appendChild(script);
      
    //     return () => {
    //       document.body.removeChild(script);
    //     }
    //   }, []);

    
    return (
        <>
        <Helmet>
            <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        </Helmet>
        <Router>
            <Account>
                {
                    token?
                    <>
                        <Routes>
                           
                            <Route path="/" element={<MainPage/>}/>
                            <Route path="/login" element={<LoginPage/>}/>
                            <Route path="/group-settings" element={<GSetingsPage/>}/>
                            <Route path="/customer_portraits" element={<SegmentationPage/>}/>
                            <Route path="/segment_datasets" element={<SegmentationDatasetPage/>}/>
                            <Route path="/campaigns" element={<Campaigns/>}/>
                            <Route path="/analytics" element={<Analytics/>}/>
                            <Route path="/datasets" element={<Datasets/>}/>
                            <Route path="/settings" element={<Settings/>}/>
                        </Routes>
                        <LiveChatWidget license="15041331"/>
                    </>
                    :
                    <Routes>
                        <Route path="/login" element={<LoginPage/>}/>
                    </Routes>
                }
            </Account>
        </Router>
        </>
    );
}

export default App;
