import React, {useState, useEffect} from 'react';
import Select, { components } from 'react-select'
import { observer } from 'mobx-react'
import './index.css';
import GlobalState, {allModels, changemodelflag, adddataset, changeActiveModel, changeActiveModeldatasetPrew,changemodalstottal, changenumsubmenu, changepopupdeleteModel, changeallpagesmodels, minusnumpagesmodels, plusnumpagesmodels, changedatasetinModel,changeInputDatasetName} from "../../stores/GlobalState";
import Pool from "../autorize/UserPool";
import Domen from "../../stores/Domen";
import Addmodels from "./addmodel"
import Openmodels from "./openmodel"
import lang from "../../lang"
const Models = observer(() => {
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [addmodel, setAddmodel] = useState(0);
    const [activemodel, setActivemodel] = useState(0);
    const [optionfields, setOptionfields] = useState([]);
    const [load, setLoad] = useState(1);
    const [totalmodel, setTotalmodel] = useState(0);

    changenumsubmenu(2)

    async function getDataAttach()
    {
       
        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `dataset`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();

            if(data&&data.items.length>0)
            {
                data.items?.map((n, id)=>
                    {
                       
                        var dataset = {
                            id: n.id,
                            name: n.name,
                            source: n.source,
                            last_changes: n.upload_time,
                            rows: n.rowsCount,
                            columns: n.columnsCount,
                            object_ID: n.objectId,
                            used_in_models: n.usedInModels,
                            file_key: n.id,
                            file_name: n.file_name,
                            key_column: n.objectId,
                            status: n.status,
                            upload_time: n.upload_time,
                            user_id: n.user_id,
                            columnNames: n.columnNames
                        }
                        
                        adddataset(dataset);
                        return "";
                    }
                    
                )
            }
        }
        else
        {
            const user = Pool.getCurrentUser();
            user.getSession((err, session) => {
                if (err) {
                    console.error("Error fetching user session:", err);                       
                } 
                else 
                {
                    if (!session) {
                        console.log("No session found");
                        return;
                    }
                    else
                    {
                        const refreshToken  = session.getRefreshToken();
                        user.refreshSession(refreshToken, (err, result) => {
                            const { accessToken } = result;
                            localStorage.setItem("token", accessToken.jwtToken);
                            getDataAttach();
                        });
                    }
                    
                }
            })
        }
    }

    
    async function getModels()
    {
        var m = [];
        allModels(m)
        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `model?page=`+GlobalState.numpagesmodels+`&limit=10`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();
          
            changemodalstottal(data.total)
            if(data&&data.items.length>0)
            {
                data.items?.map((n)=>
                {
                    m = [...m, n];
                    
                })
                allModels(m)
                var tottoppages = data.total/10;
                var y = Math.ceil(tottoppages)
                changeallpagesmodels(y)
            }
            
        }
        else
        {
            const user = Pool.getCurrentUser();
            user.getSession((err, session) => {
                if (err) {
                    console.error("Error fetching user session:", err);                       
                } 
                else 
                {
                    if (!session) {
                        console.log("No session found");
                        return;
                    }
                    else
                    {
                        const refreshToken  = session.getRefreshToken();
                        user.refreshSession(refreshToken, (err, result) => {
                            const { accessToken } = result;
                            localStorage.setItem("token", accessToken.jwtToken);
                            getModels();
                        });
                    }
                    
                }
            })
        }
    } 
    async function getModel(activemodel)
    {
        
        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `model/`+activemodel.id, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();
         
            if(data.lastTrain.state=="done"&&Object.keys(data.lastTrain?.metadata).length!==0)
            {
                changeActiveModel(data);
                setLoad(2);
                setTimeout(function(){
                    setLoad(3);
                }, 2000)
                adddatasetprewWorkspacecheck();
            }
            else if(data.lastTrain.state=="failed")
            {
                changeActiveModel(data);
                setLoad(4);
            }
            else if(data.lastTrain.state=="in_progress")
            {
                changeActiveModel(data);
                setTimeout(function(){
                    getModel(activemodel)
                }, 30000)
                //changeActiveModel(data);
                setLoad(1);
            }
            else{
                setTimeout(function(){
                    getModel(activemodel)
                }, 30000)
                setLoad(1);
            }
            
        }
       
    } 
    const adddatasetprewWorkspacecheck = async () => {

        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `dataset/`+GlobalState.activemodel?.lastTrain?.featureDataset+`/preview`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        
        if(response.status=="200")
        {
            const data = await response.json();
            changeActiveModeldatasetPrew(data.sample.slice(0, 3));
            changedatasetinModel(data)
            changeInputDatasetName(data.id)
        } 
    }
    async function deletemodel(n){
       
        changepopupdeleteModel(n);
    }
    
    if(GlobalState.modelflag==0){ 
        getModels();
        getDataAttach();
        changemodelflag(1);
    }

    const openmodel = (n) =>{
        setAddmodel(2)
        setActivemodel(n)
        getModel(n)
    }

    const downloadlink = async(n)=>{
    
        var token = localStorage.getItem("token");
        localStorage.setItem("countactivetime", Date.now());
        const response = await fetch(Domen.url + `model/`+n.id+`/prediction/last/generateDownloadLink`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();
            window.location.href=data.url;
        }
        else
        {
            const data = await response.json();
            alert(JSON.stringify(data))
        }
    }
    const minusnumpagesmodels0 = () =>{
        minusnumpagesmodels();
        getModels();
    }
    const plusnumpagesmodels0= () =>{
        plusnumpagesmodels();
        getModels();
    }

    return (
        <div className='models_block'>
            {
                addmodel==1?<Addmodels {...{setAddmodel}}/>:addmodel==2?<Openmodels {...{activemodel, setAddmodel, load, setLoad}}/>:
                <>
                    <div className='body-segment datasets models'>

                    
                        <div className='models_bottom' onClick={()=>setAddmodel(1)}>
                            
                            <svg  className='def_model_but' width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M33.4226 15.4211C33.7799 16.0399 33.7799 16.8022 33.4226 17.4211L26.3668 29.6421C26.0096 30.2609 25.3493 30.6421 24.6348 30.6421L10.5231 30.6421C9.80859 30.6421 9.14833 30.2609 8.79107 29.6421L1.73524 17.4211C1.37798 16.8022 1.37798 16.0399 1.73524 15.4211L8.79107 3.2C9.14834 2.5812 9.80859 2.2 10.5231 2.2L24.6348 2.2C25.3493 2.2 26.0096 2.5812 26.3668 3.2L33.4226 15.4211Z" fill="#F5C538" stroke="#F5C538"/>
                                <path d="M18 9V23" stroke="#020203" stroke-linecap="round"/>
                                <path d="M18 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                <path d="M18 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                <path d="M11 16H25" stroke="#020203" stroke-linecap="round"/>
                                <path d="M11 16H25" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                <path d="M11 16H25" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            </svg>

                            <svg className='hov_model_but' width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M33.4226 15.4211C33.7799 16.0399 33.7799 16.8022 33.4226 17.4211L26.3668 29.6421C26.0096 30.2609 25.3493 30.6421 24.6348 30.6421L10.5231 30.6421C9.80859 30.6421 9.14833 30.2609 8.79107 29.6421L1.73524 17.4211C1.37798 16.8022 1.37798 16.0399 1.73524 15.4211L8.79107 3.2C9.14834 2.5812 9.80859 2.2 10.5231 2.2L24.6348 2.2C25.3493 2.2 26.0096 2.5812 26.3668 3.2L33.4226 15.4211Z" fill="#41A8B1" stroke="#41A8B1"/>
                                <path d="M18 9V23" stroke="#020203" stroke-linecap="round"/>
                                <path d="M18 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                <path d="M18 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                <path d="M11 16H25" stroke="#020203" stroke-linecap="round"/>
                                <path d="M11 16H25" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                <path d="M11 16H25" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            </svg>
                            {langv?.models?.text1}
                        </div>
                        <div className='line_head'></div>
                        <div className='betta'>
                            <div className='betta_inside'>
                            {langv?.beta?.head_text}
                            </div>
                        </div>
                    </div>
                    <div className='models_body'>
                        <div className='models_head'>
                            <div className='models_col text_col'>
                            {langv?.models?.text2}
                            </div>
                            {/* <div className='models_col num_col'>
                            {langv?.models?.text3}
                            </div>
                            <div className='models_col num_col'>
                            {langv?.models?.text4}
                            </div>
                            <div className='models_col num_col'>
                            {langv?.models?.text5}
                            </div>
                            <div className='models_col num_col'>
                            {langv?.models?.text6}
                            </div>
                            <div className='models_col num_col'>
                            {langv?.models?.text7}
                            </div>
                            <div className='models_col num_col'>
                            {langv?.models?.text8}
                            </div> */}
                            <div className='models_col text_col'>
                            {langv?.models?.text9}
                            </div>
                            <div className='models_col text_col'>
                            {langv?.models?.text10}
                            </div>
                            {/* <div className='models_col text_col'>
                            {langv?.models?.text11}
                            </div>
                            <div className='models_col text_col'>
                            {langv?.models?.text12}
                            </div>
                            <div className='models_col text_col'>
                            {langv?.models?.text13}
                            </div>
                            <div className='models_col text_col'>
                            {langv?.models?.text14}
                            </div> */}
                            <div style={{width: "200px"}}></div>
                        </div>
                        <div className='models_lines'>
                            {
                                GlobalState.models.map((n)=>
                                    <div className='models_line'>
                                        <div className='icon_but' >
                                            <svg className='ferdta' onClick={()=>openmodel(n)} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.8964 5.89645C13.2114 6.21143 13.75 5.98835 13.75 5.54289V0.75H8.95711C8.51165 0.75 8.28857 1.28857 8.60355 1.60355L10.2175 3.2175L2.7175 10.7175L1.10355 9.10355C0.788572 8.78857 0.25 9.01165 0.25 9.45711V14.25H5.04289C5.48835 14.25 5.71143 13.7114 5.39645 13.3964L3.7825 11.7825L11.2825 4.2825L12.8964 5.89645Z" fill="#1C1E20"/>
                                            </svg>
                                            {/* {
                                                n.deploymentState=="done"?
                                                <svg className='ferdta2 dow_link' width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.5 24L17 13.5H10.5V3L4 13.5H10.5V24Z" stroke="#f5c538" stroke-opacity="0.6" stroke-width="1.5"/>
                                                </svg>
                                                :
                                                <svg className='ferdta2 dow_link' width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.5 24L17 13.5H10.5V3L4 13.5H10.5V24Z" stroke="#1C1E20" stroke-opacity="0.6" stroke-width="1.5"/>
                                                </svg>
                                            }
                                            {
                                                n.outputDatasetDownloadAvailable?
                                                <svg className='dow_link dow_link_h' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>downloadlink(n)}>
                                                    <path d="M9.29053 16L5.1402 11.834C4.82613 11.5187 5.04942 10.9811 5.49442 10.9811H8.58391V1H9.99714V10.9811H13.0866C13.5316 10.9811 13.7549 11.5187 13.4409 11.834L9.29053 16Z" fill="#777879"/>
                                                    <path d="M2.29053 17V18H3.29053H9.29053H15.2905H16.2905V17H2.29053Z" fill="#777879"/>
                                                </svg>
                                                :
                                                <svg className='dow_link' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.29053 16L5.1402 11.834C4.82613 11.5187 5.04942 10.9811 5.49442 10.9811H8.58391V1H9.99714V10.9811H13.0866C13.5316 10.9811 13.7549 11.5187 13.4409 11.834L9.29053 16Z" fill="#777879"/>
                                                    <path d="M2.29053 17V18H3.29053H9.29053H15.2905H16.2905V17H2.29053Z" fill="#777879"/>
                                                </svg>
                                            } */}
                                            
                                            
                                        </div>
                                        <div className='models_col_line text_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder={langv?.models?.text15}
                                                className=""
                                                value={n.name}
                                            />
                                        </div>
                                        {/* <div className='models_col_line num_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className=""
                                                value={n.metrics?.accuracy}
                                            />
                                        </div>
                                        <div className='models_col_line num_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className=""
                                                value={n.metrics?.targets?n.metrics?.targets[n.metrics?.targets?.length - 1].recall:""}
                                            />
                                        </div>
                                        <div className='models_col_line num_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className=""
                                                value={n.metrics?.targets?n.metrics?.targets[n.metrics?.targets?.length - 1].precision:""}
                                            />
                                        </div>
                                        <div className='models_col_line num_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className=""
                                                value={n.metrics?.targets?n.metrics?.targets[n.metrics?.targets?.length - 1].f1Score:""}
                                            />
                                        </div>
                                        <div className='models_col_line num_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className=""
                                                value={n.metrics?.mae}
                                            />
                                        </div>
                                        <div className='models_col_line num_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className=""
                                                value={n.metrics?.r2Score}
                                            />
                                        </div> */}
                                        <div className='models_col_line text_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder="No"
                                                className=""
                                                value={n.trainingState}
                                            />
                                        </div>
                                        <div className='models_col_line text_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder="No"
                                                className=""
                                                value={n.deploymentState}
                                            />
                                        </div>
                                        {/* <div className='models_col_line text_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className=""
                                                value={n.featureDatasetName}
                                            />
                                        </div>
                                        <div className='models_col_line text_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className=""
                                                value={n.targetDatasetName}
                                            />
                                        </div>
                                        <div className='models_col_line text_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className=""
                                                value={n.inputDatasetName}
                                            />
                                        </div>
                                        <div className='models_col_line text_col'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className=""
                                                value={n.outputDatasetName}
                                            />
                                        </div> */}
                                        
                                        <div className='icon_but dubl' style={{marginLeft: "0px", marginRight: "40px"}}>
                                            {
                                                n.outputDatasetDownloadAvailable?
                                                <div className="dubl_dow" onClick={()=>downloadlink(n)}>
                                                    <svg className='dow_link def_link' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 16.599L8.30441 12.9024C7.98949 12.5874 8.21259 12.0489 8.65801 12.0489H11.3571V3H12.6429V12.0489H15.342C15.7874 12.0489 16.0105 12.5874 15.6956 12.9024L12 16.599ZM3 21V19.7143H4.28571H19.7143H21V21H3Z" fill="#020203"/>
                                                        <path d="M12 16.599L8.30441 12.9024C7.98949 12.5874 8.21259 12.0489 8.65801 12.0489H11.3571V3H12.6429V12.0489H15.342C15.7874 12.0489 16.0105 12.5874 15.6956 12.9024L12 16.599ZM3 21V19.7143H4.28571H19.7143H21V21H3Z" fill="black" fill-opacity="0.2"/>
                                                        <path d="M12 16.599L8.30441 12.9024C7.98949 12.5874 8.21259 12.0489 8.65801 12.0489H11.3571V3H12.6429V12.0489H15.342C15.7874 12.0489 16.0105 12.5874 15.6956 12.9024L12 16.599ZM3 21V19.7143H4.28571H19.7143H21V21H3Z" fill="black" fill-opacity="0.2"/>
                                                    </svg>
                                                    <svg className='hov_link' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 16.599L8.30441 12.9024C7.98949 12.5874 8.21259 12.0489 8.65801 12.0489H11.3571V3H12.6429V12.0489H15.342C15.7874 12.0489 16.0105 12.5874 15.6956 12.9024L12 16.599ZM3 21V19.7143H4.28571H19.7143H21V21H3Z" fill="#F5C538"/>
                                                    </svg>

                                                </div>
                                                :
                                                <>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g opacity="0.1">
                                                        <path d="M12 16.599L8.30441 12.9024C7.98949 12.5874 8.21259 12.0489 8.65801 12.0489H11.3571V3H12.6429V12.0489H15.342C15.7874 12.0489 16.0105 12.5874 15.6956 12.9024L12 16.599ZM3 21V19.7143H4.28571H19.7143H21V21H3Z" fill="#020203"/>
                                                        <path d="M12 16.599L8.30441 12.9024C7.98949 12.5874 8.21259 12.0489 8.65801 12.0489H11.3571V3H12.6429V12.0489H15.342C15.7874 12.0489 16.0105 12.5874 15.6956 12.9024L12 16.599ZM3 21V19.7143H4.28571H19.7143H21V21H3Z" fill="black" fill-opacity="0.2"/>
                                                        <path d="M12 16.599L8.30441 12.9024C7.98949 12.5874 8.21259 12.0489 8.65801 12.0489H11.3571V3H12.6429V12.0489H15.342C15.7874 12.0489 16.0105 12.5874 15.6956 12.9024L12 16.599ZM3 21V19.7143H4.28571H19.7143H21V21H3Z" fill="black" fill-opacity="0.2"/>
                                                        </g>
                                                    </svg>
                                                    
                                                </>

                                            }
                                            <div className="dubl_dow">
                                                <svg className='def_link' onClick={()=>deletemodel(n.id)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.4551 5.27344L5.90838 18.18" stroke="#EA4334" stroke-width="2" stroke-linecap="round"/>
                                                    <path d="M5.72656 5.45312L18.6332 17.9998" stroke="#EA4334" stroke-width="2" stroke-linecap="round"/>
                                                </svg>
                                                <svg className='hov_link' onClick={()=>deletemodel(n.id)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.4551 5.27344L5.90838 18.18" stroke="#D52616" stroke-width="2" stroke-linecap="round"/>
                                                    <path d="M5.72656 5.45312L18.6332 17.9998" stroke="#D52616" stroke-width="2" stroke-linecap="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div style={{minWidth: "200px"}}></div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className='pagination_line'>
                        <div className='countpagepagination'>
                            {langv?.dataset?.main?.text9}  {(GlobalState.numpagesmodels*1-1)*10+1} -&nbsp;
                            
                            {
                                GlobalState.numpagesmodels*10<GlobalState.modelstottal?
                                GlobalState.numpagesmodels*10
                                :
                                GlobalState.modelstottal
                            }
                            &nbsp;of {GlobalState.modelstottal} {langv?.dataset?.main?.text10}
                        </div>
                        <div className='pagination pagdatasets'>
                            {
                                GlobalState.numpagesmodels<2?
                                <div className='arrowP' >
                                    <svg className='cursorenone' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.1">
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="#020203"/>
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                        <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="#020203"/>
                                        <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                                        <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                                        </g>
                                    </svg>
                                </div>
                                :
                                <div className='arrowP' onClick={()=>minusnumpagesmodels0()}>
                                    <svg className='def_arrow' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.4">
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="#020203"/>
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                        <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="#020203"/>
                                        <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                                        <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                                        </g>
                                    </svg>
                                    <svg className='hov_arrow' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="url(#paint0_linear_3491_117361)"/>
                                        <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="#020203"/>
                                        <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                                        <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_3491_117361" x1="1" y1="13" x2="45" y2="13" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#F5C538"/>
                                        <stop offset="1" stop-color="#F5C538" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            }

                            <div className='pagenum'>
                                {
                                    GlobalState.numpagesmodels<10?
                                    <span>{"0"+GlobalState.numpagesmodels}</span>:
                                    GlobalState.numpagesmodels
                                }
                                / 
                                {
                                    GlobalState.allpagesmodels<10?
                                    "0"+GlobalState.allpagesmodels:
                                    GlobalState.allpagesmodels
                                }
                            </div>

                            {
                                GlobalState.allpagesmodels*10<10?
                                <div className='arrowP' onClick={()=>plusnumpagesmodels0()}>
                                    <svg className='cursorenone' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.1">
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="#020203"/>
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                        <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="#020203"/>
                                        <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                        <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                        </g>
                                    </svg>
                                </div>
                                :
                                GlobalState.allpagesmodels==GlobalState.numpagesmodels?
                                <div className='arrowP' onClick={()=>plusnumpagesmodels0()}>
                                    <svg className='cursorenone' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.1">
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="#020203"/>
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                        <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="#020203"/>
                                        <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                        <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                        </g>
                                    </svg>
                                </div>
                                :
                                <div className='arrowP' onClick={()=>plusnumpagesmodels0()}>
                                    <svg className='def_arrow' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.4">
                                    <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="#020203"/>
                                    <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                    <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                    <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="#020203"/>
                                    <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                    <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                    </g>
                                    </svg>
                                    <svg className='hov_arrow' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="url(#paint0_linear_3491_117355)"/>
                                        <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="#020203"/>
                                        <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                        <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_3491_117355" x1="53" y1="13" x2="10" y2="13" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#F5C538"/>
                                        <stop offset="1" stop-color="#F5C538" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            }
                            
                        </div>
                    </div>
                </>
            }
        </div>
    );
})

export default Models;
